// react
import { useState } from 'react';

// @mui
// -- styles
import { useTheme } from '@mui/material/styles';
// -- components
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

// react intl
import { useIntl, FormattedMessage } from 'react-intl';

// filepond
import { FilePond } from 'react-filepond';

// forms
import { useController, UseControllerProps } from 'react-hook-form';

// hooks
// -- redux
import { useAppSelector } from '../../../hooks/useRedux';

// ----------------------------------------------------------------------

interface InputProps extends UseControllerProps<any> {
    allowDrop?: boolean;
    allowBrowse?: boolean;
    allowPaste?: boolean;
    allowMultiple?: boolean;
    allowReplace?: boolean;
    allowRevert?: boolean;
    allowProcess?: boolean;
    allowReorder?: boolean;
    maxFiles?: number | null;
    maxFileSize?: string | null;
    maxTotalFileSize?: string | null;
    localFilesBaseUri?: string;
    localFiles?: boolean;
    labelRequired?: boolean;
    labelText?: string;
    translateLabel?: boolean;
    disabled?: boolean;
    setValue?: any;
}

// ----------------------------------------------------------------------

const Input = ({
    allowDrop = true,
    allowBrowse = true,
    allowPaste = false,
    allowMultiple = false,
    allowReplace = true,
    allowRevert = true,
    allowProcess = true,
    allowReorder = false,
    maxFiles = null,
    maxFileSize = null,
    maxTotalFileSize = null,
    localFiles = false,
    localFilesBaseUri = '',
    labelRequired = false,
    labelText = undefined,
    translateLabel = false,
    disabled = false,
    setValue,
    ...props
}: InputProps) => {
    // hooks
    // -- theme
    const theme = useTheme();
    // -- i18n
    const intl = useIntl();
    // -- forms
    const { field, formState } = useController(props);
    // -- redux
    const session = useAppSelector((state) => state.session);

    // state
    // -- local files state
    const [areLocalFilesLoaded, setAreLocalFilesLoaded] = useState<boolean>(false);

    return (
        <>
            <Stack spacing={1}>
                {labelText && (
                    <InputLabel
                        required={labelRequired}
                        htmlFor={props.name}
                        sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 700
                        }}
                    >
                        {translateLabel ? <FormattedMessage id={labelText} /> : labelText}
                    </InputLabel>
                )}

                <FilePond
                    {...field}
                    ref={field.ref}
                    disabled={disabled}
                    allowDrop={allowDrop}
                    allowBrowse={allowBrowse}
                    allowPaste={allowPaste}
                    allowMultiple={allowMultiple}
                    allowReplace={allowReplace}
                    allowRevert={allowRevert}
                    allowProcess={allowProcess}
                    allowReorder={allowReorder}
                    maxFiles={maxFiles}
                    files={field.value}
                    credits={false}
                    instantUpload={false}
                    allowFileTypeValidation
                    acceptedFileTypes={['image/jpeg', 'image/jpg', 'image/png', 'image/bmp']}
                    allowFileSizeValidation
                    maxFileSize={maxFileSize || '10MB'}
                    maxTotalFileSize={maxTotalFileSize || '10MB'}
                    server={{
                        load: (source, load, error, _progress, _abort, _headers) => {
                            fetch(`${localFilesBaseUri}${source}`, {
                                    headers: {
                                        Authorization: `Token ${session.authorization.access_token}`
                                    }
                                })
                                .then((res) => res.blob())
                                .then((res) => {
                                    const file = blobToFile(res, source);

                                    load(res);

                                    setValue(field.name, [file]);
                                })
                                .then(() => {
                                    setAreLocalFilesLoaded(true);
                                })
                                .catch(error);
                        }
                    }}
                    onupdatefiles={(fileItems) => {
                        if (!localFiles) {
                            field.onChange(fileItems.map((fileItem) => fileItem.file));
                        }

                        if (localFiles && areLocalFilesLoaded) {
                            field.onChange(fileItems.map((fileItem) => fileItem.file));
                        }
                    }}
                    labelFileTypeNotAllowed={intl.formatMessage({ id: 'filePondInput.notAllowedFileTypeError' })}
                    fileValidateTypeLabelExpectedTypes={intl.formatMessage({ id: 'filePondInput.expectedFileTypes' })}
                    labelIdle={intl.formatMessage({ id: 'filePondInput.labelIdle' })}
                    labelInvalidField={intl.formatMessage({ id: 'filePondInput.labelInvalidField' })}
                    labelFileWaitingForSize={intl.formatMessage({ id: 'filePondInput.labelFileWaitingForSize' })}
                    labelFileSizeNotAvailable={intl.formatMessage({ id: 'filePondInput.labelFileSizeNotAvailable' })}
                    labelFileLoading={intl.formatMessage({ id: 'filePondInput.labelFileLoading' })}
                    labelFileLoadError={intl.formatMessage({ id: 'filePondInput.labelFileLoadError' })}
                    labelFileProcessing={intl.formatMessage({ id: 'filePondInput.labelFileProcessing' })}
                    labelFileProcessingComplete={intl.formatMessage({ id: 'filePondInput.labelFileProcessingComplete' })}
                    labelFileProcessingAborted={intl.formatMessage({ id: 'filePondInput.labelFileProcessingAborted' })}
                    labelFileProcessingError={intl.formatMessage({ id: 'filePondInput.labelFileProcessingError' })}
                    labelFileProcessingRevertError={intl.formatMessage({ id: 'filePondInput.labelFileProcessingRevertError' })}
                    labelFileRemoveError={intl.formatMessage({ id: 'filePondInput.labelFileRemoveError' })}
                    labelTapToCancel={intl.formatMessage({ id: 'filePondInput.labelTapToCancel' })}
                    labelTapToRetry={intl.formatMessage({ id: 'filePondInput.labelTapToRetry' })}
                    labelTapToUndo={intl.formatMessage({ id: 'filePondInput.labelTapToUndo' })}
                    labelButtonRemoveItem={intl.formatMessage({ id: 'filePondInput.labelButtonRemoveItem' })}
                    labelButtonAbortItemLoad={intl.formatMessage({ id: 'filePondInput.labelButtonAbortItemLoad' })}
                    labelButtonRetryItemLoad={intl.formatMessage({ id: 'filePondInput.labelButtonRetryItemLoad' })}
                    labelButtonAbortItemProcessing={intl.formatMessage({ id: 'filePondInput.labelButtonAbortItemProcessing' })}
                    labelButtonUndoItemProcessing={intl.formatMessage({ id: 'filePondInput.labelButtonUndoItemProcessing' })}
                    labelButtonRetryItemProcessing={intl.formatMessage({ id: 'filePondInput.labelButtonRetryItemProcessing' })}
                    labelButtonProcessItem={intl.formatMessage({ id: 'filePondInput.labelButtonProcessItem' })}
                    labelMaxFileSizeExceeded={intl.formatMessage({ id: 'filePondInput.labelMaxFileSizeExceeded' })}
                    labelMaxFileSize={intl.formatMessage({ id: 'filePondInput.labelMaxFileSize' })}
                    labelMaxTotalFileSizeExceeded={intl.formatMessage({ id: 'filePondInput.labelMaxTotalFileSizeExceeded' })}
                    labelMaxTotalFileSize={intl.formatMessage({ id: 'filePondInput.labelMaxTotalFileSize' })}
                />
            </Stack>

            {formState.errors[`${props.name}`] && (
                <FormHelperText sx={{ mt: -1 }} error>
                    {formState.errors[`${props.name}`]?.message as string}
                </FormHelperText>
            )}
        </>
    );
};

// ----------------------------------------------------------------------

const blobToFile = (theBlob: Blob, fileName: string): File => {
    return new File([theBlob as Blob], fileName, {
        lastModified: new Date().getTime(),
        type: theBlob.type
    });
};

// ----------------------------------------------------------------------

export default Input;
