// react
import { ReactNode } from "react";

// prop types
import PropTypes from "prop-types";

// farmer motion
import { LazyMotion } from "framer-motion";

// ----------------------------------------------------------------------

const loadFeatures = () => import("./features.js").then((res) => res.default);

// ----------------------------------------------------------------------

function FramerMotion({ children }: { children: ReactNode }) {
    return (
        <LazyMotion strict features={loadFeatures}>
            {children}
        </LazyMotion>
    );
}

FramerMotion.propTypes = {
    children: PropTypes.node.isRequired,
};

// ----------------------------------------------------------------------

export default FramerMotion;
