// @mui
// -- styles
import { useTheme } from "@mui/material/styles";
// -- components
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";

// react intl
import { useIntl, FormattedMessage } from "react-intl";

// forms
import { useController, UseControllerProps } from "react-hook-form";

// ----------------------------------------------------------------------

interface InputProps extends UseControllerProps<any> {
    type?: string;
    multiline?: boolean;
    rows?: number;
    labelRequired?: boolean;
    labelText?: string;
    translateLabel?: boolean;
    placeholderText?: string;
    translatePlaceholder?: boolean;
    onChange?: any;
    allowHelperText?: boolean;
    disabled?: boolean;
}

// ----------------------------------------------------------------------

const Input = ({
    type = "text",
    multiline = undefined,
    rows = undefined,
    labelRequired = false,
    labelText = undefined,
    translateLabel = false,
    placeholderText = undefined,
    translatePlaceholder = false,
    onChange = undefined,
    allowHelperText = true,
    disabled = false,
    ...props
}: InputProps) => {
    // hooks
    // -- theme
    const theme = useTheme();
    // -- i18n
    const intl = useIntl();
    // -- forms
    const { field, formState } = useController(props);

    return (
        <>
            <Stack spacing={1}>
                {labelText && (
                    <InputLabel
                        required={labelRequired}
                        htmlFor={props.name}
                        sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 700,
                        }}
                    >
                        {translateLabel ? (
                            <FormattedMessage id={labelText} />
                        ) : (
                            labelText
                        )}
                    </InputLabel>
                )}

                <TextField
                    autoComplete="off"
                    fullWidth
                    {...field}
                    inputRef={field.ref}
                    disabled={disabled}
                    type={type}
                    multiline={multiline}
                    rows={rows}
                    error={Boolean(formState.errors[`${field.name}`])}
                    onChange={(e) => {
                        if (onChange) {
                            onChange(e, field);
                        } else {
                            field.onChange(e);
                        }
                    }}
                    placeholder={
                        placeholderText
                            ? translatePlaceholder
                                ? intl.formatMessage({ id: placeholderText })
                                : placeholderText
                            : undefined
                    }
                    InputProps={{
                        style: {
                            paddingLeft: 10,
                            paddingRight: 10,
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: "#a0a7ba",
                        },
                    }}
                    sx={{
                        bgcolor: "#F5F5F7",
                        "& *": {
                            border: "unset",
                        },
                    }}
                />
            </Stack>

            {allowHelperText && formState.errors[`${props.name}`] && (
                <FormHelperText error sx={{ mt: 0.5 }}>
                    {formState.errors[`${props.name}`]?.message as string}
                </FormHelperText>
            )}
        </>
    );
};

// ----------------------------------------------------------------------

export default Input;
