const colors = {
    background: {
        default: "#FCF5EB",
    },

    // text: {
    //   main: "#7b809a",
    //   focus: "#7b809a",
    // },

    transparent: {
        main: "transparent",
    },

    white: {
        main: "#ffffff",
        focus: "#ffffff",
    },

    black: {
        light: "#000000",
        main: "#000000",
        focus: "#000000",
    },

    primary: {
        main: "#002c62",
        focus: "#002c62",
    },

    // secondary: {
    //   main: "#7b809a",
    //   focus: "#8f93a9",
    // },

    info: {
        main: "#039fbe",
        focus: "#039fbe",
    },

    // success: {
    //   main: "#4CAF50",
    //   focus: "#67bb6a",
    // },

    // warning: {
    //   main: "#fb8c00",
    //   focus: "#fc9d26",
    // },

    // error: {
    //   main: "#F44335",
    //   focus: "#f65f53",
    // },

    // light: {
    //   main: "#f0f2f5",
    //   focus: "#f0f2f5",
    // },

    dark: {
        main: "#111B21",
        focus: "#111B21",
    },

    // grey: {
    //   100: "#f8f9fa",
    //   200: "#f0f2f5",
    //   300: "#dee2e6",
    //   400: "#ced4da",
    //   500: "#adb5bd",
    //   600: "#6c757d",
    //   700: "#495057",
    //   800: "#343a40",
    //   900: "#212529",
    // },

    // gradients: {
    //   primary: {
    //     main: "#EC407A",
    //     state: "#D81B60",
    //   },

    secondary: {
        main: "#5e5e5e",
        state: "#5e5e5e",
    },

    //   info: {
    //     main: "#49a3f1",
    //     state: "#1A73E8",
    //   },

    //   success: {
    //     main: "#66BB6A",
    //     state: "#43A047",
    //   },

    //   warning: {
    //     main: "#FFA726",
    //     state: "#FB8C00",
    //   },

    //   error: {
    //     main: "#EF5350",
    //     state: "#E53935",
    //   },

    //   light: {
    //     main: "#EBEFF4",
    //     state: "#CED4DA",
    //   },

    //   dark: {
    //     main: "#42424a",
    //     state: "#191919",
    //   },
    // },
};

export default colors;
