// react
import { ReactNode } from "react";

// prop-types
import PropTypes from "prop-types";

// @mui
// -- styles
import { useTheme } from "@mui/material/styles";
// -- components
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

// ----------------------------------------------------------------------

interface Props {
    sx?: any;
    bold?: boolean;
    light?: boolean;
    color?: string;
    size?: string;
    children: ReactNode;
    [rest: string]: any;
}

// ----------------------------------------------------------------------

function H1({
    sx = {},
    bold = false,
    light = false,
    size = "large",
    color = undefined,
    children,
    ...rest
}: Props) {
    // hooks
    const theme = useTheme();

    // breakpoints
    const mdOnlyBreakpoint = useMediaQuery(theme.breakpoints.only("md"));
    const mdDownBreakpoint = useMediaQuery(theme.breakpoints.down("md"));

    const smallLineHeight = mdDownBreakpoint ? "36px" : "44px";
    const smallLetterSpacing = "inherit";
    const smallFontSize = mdDownBreakpoint ? "26px" : "34px";

    const mediumLineHeight = 1;
    const mediumLetterSpacing = "-0.03em";
    const mediumFontSize = mdDownBreakpoint
        ? mdOnlyBreakpoint
            ? "40px"
            : "32px"
        : "60px";

    const largeLineHeight = 1;
    const largeLetterSpacing = mdDownBreakpoint ? "-0.03em" : "-0.04em";
    const largeFontSize = mdDownBreakpoint
        ? mdOnlyBreakpoint
            ? "34px"
            : "28px"
        : "40px";

    return (
        <Typography
            {...rest}
            variant="h1"
            color={color ?? theme.palette.primary.main}
            sx={{
                fontWeight: bold ? 800 : (light ? 300 : 400),
                lineHeight:
                    size === "small"
                        ? smallLineHeight
                        : size === "medium"
                        ? mediumLineHeight
                        : largeLineHeight,
                letterSpacing:
                    size === "small"
                        ? smallLetterSpacing
                        : size === "medium"
                        ? mediumLetterSpacing
                        : largeLetterSpacing,
                fontSize:
                    size === "small"
                        ? smallFontSize
                        : size === "medium"
                        ? mediumFontSize
                        : largeFontSize,
                ...sx,
            }}
        >
            {children}
        </Typography>
    );
}

H1.propTypes = {
    sx: PropTypes.any,
    bold: PropTypes.bool,
    color: PropTypes.string,
    size: PropTypes.string,
    children: PropTypes.node.isRequired,
};

// ----------------------------------------------------------------------

export default H1;
