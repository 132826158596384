// react
import { ReactNode } from "react";

// prop-types
import PropTypes from "prop-types";

// @mui
// -- styles
import { useTheme } from "@mui/material/styles";
// -- components
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

// ----------------------------------------------------------------------

interface Props {
    sx?: any;
    light?: boolean;
    bold?: boolean;
    color?: string;
    children: ReactNode;
    [rest: string]: any;
}

// ----------------------------------------------------------------------

function H4({
    sx = {},
    light = false,
    bold = false,
    color = undefined,
    children,
    ...rest
}: Props) {
    // hooks
    const theme = useTheme();

    // breakpoints
    const mdDownBreakpoint = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Typography
            {...rest}
            variant="h4"
            color={color ?? theme.palette.primary.main}
            sx={{
                fontWeight: light ? 300 : bold ? 500 : 400,
                lineHeight: "26px",
                fontSize: mdDownBreakpoint ? "16px" : "18px",
                letterSpacing: "inherit",
                ...sx,
            }}
        >
            {children}
        </Typography>
    );
}

H4.propTypes = {
    sx: PropTypes.any,
    light: PropTypes.bool,
    color: PropTypes.string,
    children: PropTypes.node.isRequired,
};

// ----------------------------------------------------------------------

export default H4;
