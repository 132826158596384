// react
import { StrictMode } from "react";

// react-dom
import { createRoot } from "react-dom/client";

// providers, theme, containers and contexts
// -- redux
import { Provider as ReduxProvider } from "react-redux";
// -- x date pickers
import { LocalizationProvider as XDatePickersLocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// -- theme
import ThemeProvider from "./theme";
// -- settings context
import { SettingsProvider } from "./contexts/settings";
// -- i18n context
import { LocalizationProvider } from "./contexts/localization";
// -- axios container
import { AxiosContainer } from './containers/axios';
// -- router container
import RouterContainer from "./containers/router";
// -- framer motion container
import FramerMotion from "./containers/framerMotion";
// -- rtl container
import RTLContainer from "./containers/rtl";

// filepond
import { registerPlugin } from 'react-filepond';
// -- preview
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
// -- file type
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
// -- file size
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

// redux store
import { store } from "./store";

// ----------------------------------------------------------------------

// alexandria
import "./assets/styles/fonts-alexandria.css";

// libphonenumber
import 'react-phone-input-2/lib/style.css';

// filepond
import 'filepond/dist/filepond.min.css';

// lazy image loader
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";

// swiper
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// import reportWebVitals from "./reportWebVitals";

// ----------------------------------------------------------------------

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

// ----------------------------------------------------------------------

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <StrictMode>
        <ReduxProvider store={store}>
            <XDatePickersLocalizationProvider dateAdapter={AdapterMoment}>
                <SettingsProvider>
                    <ThemeProvider>
                        <LocalizationProvider>
                            <AxiosContainer>
                                <FramerMotion>
                                    <RTLContainer>
                                        <RouterContainer />
                                    </RTLContainer>
                                </FramerMotion>
                            </AxiosContainer>
                        </LocalizationProvider>
                    </ThemeProvider>
                </SettingsProvider>
            </XDatePickersLocalizationProvider>
        </ReduxProvider>
    </StrictMode>
);

// ----------------------------------------------------------------------

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
