// @mui
// -- styles
import { useTheme } from '@mui/material/styles';
// -- components
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

// react intl
import { useIntl, FormattedMessage } from 'react-intl';

// forms
import { useController, UseControllerProps } from 'react-hook-form';

// phone
import PhoneInput from 'react-phone-input-2';

// ----------------------------------------------------------------------

interface InputProps extends UseControllerProps<any> {
    labelRequired?: boolean;
    labelText?: string;
    translateLabel?: boolean;
    disabled?: boolean;
}

// ----------------------------------------------------------------------

const Input = ({ labelRequired = false, labelText = undefined, translateLabel = false, disabled = false, ...props }: InputProps) => {
    // hooks
    // -- theme
    const theme = useTheme();
    // -- i18n
    const intl = useIntl();
    // -- forms
    const { field, formState } = useController(props);

    return (
        <>
            <Stack spacing={1}>
                {labelText && (
                    <InputLabel
                        required={labelRequired}
                        htmlFor={props.name}
                        sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 700
                        }}
                    >
                        {translateLabel ? <FormattedMessage id={labelText} /> : labelText}
                    </InputLabel>
                )}

                <PhoneInput
                    {...field}
                    autoFormat
                    country="ae"
                    enableSearch
                    disableSearchIcon
                    autocompleteSearch={false}
                    countryCodeEditable={false}
                    preferredCountries={['ae', 'sa']}
                    searchPlaceholder={intl.formatMessage({ id: 'phoneNumberInput.searchPlaceholder' })}
                    searchNotFound={intl.formatMessage({ id: 'phoneNumberInput.searchNotFound' })}
                    containerStyle={{
                        direction: 'ltr'
                    }}
                    inputProps={{
                        ref: field.ref,
                        name: props.name,
                        autoComplete: 'off',
                        disabled: disabled,
                        style: {
                            backgroundColor: '#F5F5F7',
                            minHeight: '56px',
                            width: '100%',
                            border: "unset"
                        }
                    }}
                    searchStyle={{
                        width: '93%',
                        height: '40px',
                        borderRadius: '8px'
                    }}
                />
            </Stack>

            {formState.errors[`${props.name}`] && (
                <FormHelperText error sx={{ mt: 0.5 }}>
                    {formState.errors[`${props.name}`]?.message as string}
                </FormHelperText>
            )}
        </>
    );
};

// ----------------------------------------------------------------------

export default Input;
