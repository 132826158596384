// @mui
// -- styles
import { useTheme } from '@mui/material/styles';
// -- components
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

// otp
import { MuiOtpInput } from "mui-one-time-password-input";

// react intl
import { FormattedMessage } from 'react-intl';

// forms
import { useController, UseControllerProps } from 'react-hook-form';

// ----------------------------------------------------------------------

interface InputProps extends UseControllerProps<any> {
    length: number;
    autoFocus?: boolean;
    disabled?: boolean;
    labelRequired?: boolean;
    labelText?: string;
    translateLabel?: boolean;
    allowHelperText?: boolean;
}

// ----------------------------------------------------------------------

const Input = ({
    length,
    autoFocus = false,
    disabled = false,
    labelRequired = false,
    labelText = undefined,
    translateLabel = false,
    allowHelperText = true,
    ...props
}: InputProps) => {
    // hooks
    // -- theme
    const theme = useTheme();
    // -- forms
    const { field, formState } = useController(props);

    return (
        <>
            <Stack spacing={1}>
                {labelText && (
                    <InputLabel
                        required={labelRequired}
                        htmlFor={props.name}
                        sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 700
                        }}
                    >
                        {translateLabel ? <FormattedMessage id={labelText} /> : labelText}
                    </InputLabel>
                )}

                <MuiOtpInput
                    sx={{ gap: 1 }}
                    length={length}
                    TextFieldsProps={{
                        disabled: disabled,
                    }}
                    {...field}
                />
            </Stack>

            {allowHelperText && formState.errors[`${props.name}`] && (
                <FormHelperText error sx={{ mt: 0.5 }}>
                    {formState.errors[`${props.name}`]?.message as string}
                </FormHelperText>
            )}
        </>
    );
};

// ----------------------------------------------------------------------

export default Input;
