// react
import { useState, useEffect, useRef, ReactNode } from "react";

// @mui
// -- styles
import { useTheme, Theme } from "@mui/material/styles";
// -- components
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Stepper from "@mui/material/Stepper";
import StepContent from "@mui/material/StepContent";
import Step from "@mui/material/Step";
import MuiLink from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
// -- icons
import {
    MinusCircleOutlined,
    CheckCircleFilled,
    ExclamationCircleOutlined,
    LoadingOutlined,
    ArrowLeftOutlined,
} from "@ant-design/icons";

// react intl
import { FormattedMessage, useIntl } from "react-intl";

// phone
import libphonenumber from "libphonenumber-js";

// forms
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";

// third party heplers
import { useCountdown } from "usehooks-ts";

// config
import { getLocale } from "../../../config/i18n";

// axios
import axios from "../../../containers/axios";

// hooks
import { useAppDispatch } from "../../../hooks/useRedux";
import useLocalization from "../../../hooks/useLocalization";

// redux
import { CategoryModel } from "../../../store/celebrities/categoriesSlice";
import {
    create,
    verifyOtp,
    IOption,
    IFullNameStepFormFields,
    IUsernameStepFormFields,
    IEmailAddressStepFormFields,
    IPhoneNumberStepFormFields,
    ISocialMediaURLStepFormFields,
    IIdentityStepFormFields,
    ISbisialityStepFormFields,
    IOTPFormFields,
} from "../../../store/membership/vipRequestsSlice";

// layout
import SimpleLayout from "../../../layouts/simple";

// components
// -- typography
import H1 from "../../../components/h1";
import H3 from "../../../components/h3";
import H4 from "../../../components/h4";
import Paragraph from "../../../components/paragraph";
// -- shared
import Image from "../../../components/image";
// -- forms
import TextInput from "../../../components/forms/text";
import PhoneInput from "../../../components/forms/phone";
import OTPInput from "../../../components/forms/otp";
import FilePondInput from "../../../components/forms/filePond";
import AutocompleteInput from "../../../components/forms/autocomplete";

// sections
import Promo from "./sections/promo";
import SbisialiProvides from "./sections/sbisialiProvides";

// images
// -- local
import BigSbisialiLogoImage from "../../../assets/images/Osaqw2.svg";
import VideoSectionBackgroundImage from "../../../assets/images/cawW12.png";
import BecomeASbisialiInsiderNowImage from "../../../assets/images/xdaseq.svg";

// ----------------------------------------------------------------------

interface ICategories {
    isLoading: boolean;
    isLoaded: boolean;
    hasError: boolean;
    data: CategoryModel[];
}

interface ICreateResourceForm {
    error: boolean;
    isSubmitting: boolean;
    membershipId: number;
}

interface IOTPResourceForm {
    error: boolean;
    isSubmitting: boolean;
}

interface IOTPResendForm {
    error: boolean;
    isSubmitting: boolean;
}

// ----------------------------------------------------------------------

function VipRequestPage() {
    // hooks
    // -- theme
    const theme = useTheme();
    // -- breakpoints
    const isLgDown = useMediaQuery(theme.breakpoints.down("lg"));
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
    const matchMd = useMediaQuery(theme.breakpoints.only("md"));
    const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
    const matchSm = useMediaQuery(theme.breakpoints.only("sm"));
    // -- i18n
    const intl = useIntl();
    // -- localization
    const localization = useLocalization();
    // -- refs
    const sectionRef = useRef<HTMLUListElement | null>(null);
    // -- redux
    const dispatch = useAppDispatch();
    // use hooks
    const [count, { startCountdown, resetCountdown }] = useCountdown({
        countStart: 120,
        intervalMs: 1000,
    });
    // -- forms
    // full name step form validation schemas
    const fullNameStepFormSchema = Joi.object({
        full_name: Joi.string()
            .min(1)
            .max(50)
            .required()
            .label(intl.formatMessage({ id: "forms.input.fullName.labelText" }))
            .messages({
                "any.required": intl.formatMessage({
                    id: "forms.input.fullName.errorMessages.required",
                }),
                "string.empty": intl.formatMessage({
                    id: "forms.input.fullName.errorMessages.required",
                }),
                "string.min": intl.formatMessage({
                    id: "forms.input.fullName.errorMessages.required",
                }),
                "string.max": intl.formatMessage(
                    { id: "forms.input.fullName.errorMessages.max" },
                    { length: 50 },
                ),
            }),
    });
    // username step form validation schemas
    const usernameStepFormSchema = Joi.object({
        username: Joi.string()
            .min(3)
            .max(28)
            .required()
            .regex(/^[a-z_](?!.*?\.{2})[a-z0-9_.]{1,28}[a-z0-9_]$/)
            .label(intl.formatMessage({ id: "forms.input.username.labelText" }))
            .messages({
                "any.required": intl.formatMessage({
                    id: "forms.input.username.errorMessages.required",
                }),
                "string.empty": intl.formatMessage({
                    id: "forms.input.username.errorMessages.required",
                }),
                "string.min": intl.formatMessage(
                    { id: "forms.input.username.errorMessages.min" },
                    { length: 3 },
                ),
                "string.max": intl.formatMessage(
                    { id: "forms.input.username.errorMessages.max" },
                    { length: 50 },
                ),
                "string.pattern.base": intl.formatMessage({
                    id: "forms.input.username.errorMessages.regex",
                }),
            }),
    });
    // email address step form validation schemas
    const emailAddressStepFormSchema = Joi.object({
        email: Joi.string()
            .email({ tlds: { allow: false } })
            .required()
            .label(intl.formatMessage({ id: "forms.input.email.labelText" }))
            .messages({
                "any.required": intl.formatMessage({
                    id: "forms.input.email.errorMessages.required",
                }),
                "string.empty": intl.formatMessage({
                    id: "forms.input.email.errorMessages.required",
                }),
                "string.email": intl.formatMessage({
                    id: "forms.input.email.errorMessages.invalid",
                }),
            }),
    });
    // phone number step form validation schemas
    const phoneNumberStepFormSchema = Joi.object({
        phone_number: Joi.string()
            .required()
            .label(
                intl.formatMessage({ id: "forms.input.phoneNumber.labelText" }),
            )
            .messages({
                "any.required": intl.formatMessage({
                    id: "forms.input.phoneNumber.errorMessages.required",
                }),
                "string.empty": intl.formatMessage({
                    id: "forms.input.phoneNumber.errorMessages.required",
                }),
            }),
    });
    // social media url step form validation schemas
    const socialMediaURLStepFormSchema = Joi.object({
        social_media_url: Joi.string()
            .min(1)
            .uri()
            // .max(100)
            .required()
            .label(
                intl.formatMessage({
                    id: "forms.input.socialMediaUrl.labelText",
                }),
            )
            .messages({
                "any.required": intl.formatMessage({
                    id: "forms.input.socialMediaUrl.errorMessages.required",
                }),
                "string.empty": intl.formatMessage({
                    id: "forms.input.socialMediaUrl.errorMessages.required",
                }),
                "string.min": intl.formatMessage({
                    id: "forms.input.socialMediaUrl.errorMessages.required",
                }),
                "string.uri": intl.formatMessage({
                    id: "forms.input.socialMediaUrl.errorMessages.uri",
                }),
            }),
    });
    // identity step form validation schemas
    const identityStepFormSchema = Joi.object({
        identity: Joi.array()
            .min(1)
            .required()
            .messages({
                "any.required": intl.formatMessage({
                    id: "forms.input.identity.errorMessages.required",
                }),
                "array.min": intl.formatMessage({
                    id: "forms.input.identity.errorMessages.required",
                }),
            }),
    });
    // sbisiality step form validation schemas
    const sbisialityStepFormSchema = Joi.object({
        sbisiality: Joi.object({
            label: Joi.string()
                .required()
                .label(
                    intl.formatMessage({
                        id: "forms.input.sbisiality.labelText",
                    }),
                )
                .messages({
                    "any.required": intl.formatMessage({
                        id: "forms.input.sbisiality.errorMessages.required",
                    }),
                    "string.empty": intl.formatMessage({
                        id: "forms.input.sbisiality.errorMessages.required",
                    }),
                }),
            value: Joi.number()
                .required()
                .label(
                    intl.formatMessage({
                        id: "forms.input.sbisiality.labelText",
                    }),
                )
                .messages({
                    "number.base": intl.formatMessage({
                        id: "forms.input.sbisiality.errorMessages.required",
                    }),
                    "any.required": intl.formatMessage({
                        id: "forms.input.sbisiality.errorMessages.required",
                    }),
                    "any.empty": intl.formatMessage({
                        id: "forms.input.sbisiality.errorMessages.required",
                    }),
                    "any.min": intl.formatMessage({
                        id: "forms.input.sbisiality.errorMessages.required",
                    }),
                }),
        }).messages({
            "object.base": intl.formatMessage({
                id: "forms.input.sbisiality.errorMessages.required",
            }),
        }),
    });
    // otp form validation schemas
    const otpFormSchema = Joi.object({
        otp: Joi.string()
            .min(1)
            .max(6)
            .required()
            .label(intl.formatMessage({ id: "forms.input.otp.labelText" }))
            .messages({
                "any.required": intl.formatMessage({
                    id: "forms.input.otp.errorMessages.required",
                }),
                "string.empty": intl.formatMessage({
                    id: "forms.input.otp.errorMessages.required",
                }),
                "string.min": intl.formatMessage({
                    id: "forms.input.otp.errorMessages.required",
                }),
            }),
    });
    // -- build full name step form methods
    const fullNameStepFormMethods = useForm<IFullNameStepFormFields>({
        mode: "onTouched",
        reValidateMode: "onChange",
        resolver: joiResolver(fullNameStepFormSchema),
        shouldFocusError: false,
        defaultValues: {
            full_name: "",
        },
    });
    // -- build username step form methods
    const usernameStepFormMethods = useForm<IUsernameStepFormFields>({
        mode: "onTouched",
        reValidateMode: "onChange",
        resolver: joiResolver(usernameStepFormSchema),
        shouldFocusError: false,
        defaultValues: {
            username: "",
        },
    });
    // -- build email step form methods
    const emailAddressStepFormMethods = useForm<IEmailAddressStepFormFields>({
        mode: "onTouched",
        reValidateMode: "onChange",
        resolver: joiResolver(emailAddressStepFormSchema),
        shouldFocusError: false,
        defaultValues: {
            email: "",
        },
    });
    // -- build phone number step form methods
    const phoneNumberStepFormMethods = useForm<IPhoneNumberStepFormFields>({
        mode: "onTouched",
        reValidateMode: "onChange",
        resolver: joiResolver(phoneNumberStepFormSchema),
        shouldFocusError: false,
        defaultValues: {
            phone_number: "",
        },
    });
    // -- build social media url step form methods
    const socialMediaURLStepFormMethods =
        useForm<ISocialMediaURLStepFormFields>({
            mode: "onTouched",
            reValidateMode: "onChange",
            resolver: joiResolver(socialMediaURLStepFormSchema),
            shouldFocusError: false,
            defaultValues: {
                social_media_url: "",
            },
        });
    // -- build identity step form methods
    const identityStepFormMethods = useForm<IIdentityStepFormFields>({
        mode: "onTouched",
        reValidateMode: "onChange",
        resolver: joiResolver(identityStepFormSchema),
        shouldFocusError: false,
        defaultValues: {
            identity: [],
        },
    });
    // -- build sbisiality step form methods
    const sbisialityStepFormMethods = useForm<ISbisialityStepFormFields>({
        mode: "onTouched",
        reValidateMode: "onChange",
        resolver: joiResolver(sbisialityStepFormSchema),
        shouldFocusError: false,
        defaultValues: {
            sbisiality: "",
        },
    });
    // -- build otp form methods
    const otpResourceFormMethods = useForm<IOTPFormFields>({
        mode: "onTouched",
        reValidateMode: "onChange",
        resolver: joiResolver(otpFormSchema),
        shouldFocusError: false,
        defaultValues: {
            otp: "",
        },
    });

    // states
    // -- steps
    const [activeStep, setActiveStep] = useState<number>(0);
    // -- categories
    const [categories, setCategories] = useState<ICategories>({
        isLoading: false,
        isLoaded: false,
        hasError: false,
        data: [],
    });
    // -- create resource form
    const [createResourceForm, setCreateResourceForm] =
        useState<ICreateResourceForm>({
            error: false,
            isSubmitting: false,
            membershipId: 0,
        });
    // -- otp resource form
    const [otpResourceForm, setOtpResourceForm] = useState<IOTPResourceForm>({
        error: false,
        isSubmitting: false,
    });
    // -- otp resend form
    const [otpResendForm, setOtpResendForm] = useState<IOTPResendForm>({
        error: false,
        isSubmitting: false,
    });

    // get current locale
    const locale = getLocale(localization.locale);

    // fetch categories
    const fetchCategories = () => {
        setCategories({
            ...categories,
            isLoading: true,
            isLoaded: false,
            hasError: false,
        });

        axios
            .get("celebrities/categories", {
                params: {
                    is_pagination: false,
                },
            })
            .then((res) => {
                setCategories({
                    ...categories,
                    hasError: false,
                    isLoading: false,
                    isLoaded: true,
                    data: [...res.data],
                });
            })
            .catch(() => {
                setCategories({
                    ...categories,
                    isLoaded: false,
                    isLoading: false,
                    hasError: true,
                });
            });
    };

    // resend otp
    const resendOtp = () => {
        setOtpResendForm({
            ...otpResendForm,
            error: false,
            isSubmitting: true,
        });
        setOtpResourceForm({ ...otpResourceForm, isSubmitting: true });

        axios
            .post("membership/requests/vip/otp/resend", {
                membership: createResourceForm.membershipId,
            })
            .then((res) => {
                setOtpResendForm({
                    ...otpResendForm,
                    error: false,
                    isSubmitting: false,
                });

                setOtpResourceForm({ ...otpResourceForm, isSubmitting: false });

                resetCountdown();
                startCountdown();
            })
            .catch(() => {
                setOtpResendForm({
                    ...otpResendForm,
                    error: true,
                    isSubmitting: false,
                });

                setOtpResourceForm({ ...otpResourceForm, isSubmitting: false });
            });
    };

    // submit handler
    // -- back step form callback(s)
    const handleBackButton = () => {
        sectionRef.current?.scrollIntoView({
            block: "start",
            behavior: "smooth",
        });

        setActiveStep(activeStep - 1);
    };
    // -- next step form callback(s)
    const handleNextStepContinue: SubmitHandler<
        | IFullNameStepFormFields
        | IUsernameStepFormFields
        | IEmailAddressStepFormFields
        | IPhoneNumberStepFormFields
        | ISocialMediaURLStepFormFields
        | IIdentityStepFormFields
        | ISbisialityStepFormFields
    > = (
        data:
            | IFullNameStepFormFields
            | IUsernameStepFormFields
            | IEmailAddressStepFormFields
            | IPhoneNumberStepFormFields
            | ISocialMediaURLStepFormFields
            | IIdentityStepFormFields
            | ISbisialityStepFormFields,
    ) => {
        sectionRef.current?.scrollIntoView({
            block: "start",
            behavior: "smooth",
        });

        setActiveStep(activeStep + 1);
    };
    // -- create form callback(s)
    const handleCreate = () => {
        setCreateResourceForm({
            ...createResourceForm,
            error: false,
            isSubmitting: true,
        });

        const phoneNumber = libphonenumber(
            `+${phoneNumberStepFormMethods.getValues("phone_number")}`,
        );

        dispatch(
            create({
                full_name: fullNameStepFormMethods.getValues("full_name"),
                email: emailAddressStepFormMethods.getValues("email"),
                username: usernameStepFormMethods.getValues("username"),
                country_code: phoneNumber!.countryCallingCode,
                phone_number: phoneNumber!.nationalNumber,
                identity: identityStepFormMethods.getValues("identity"),
                social_media_url:
                    socialMediaURLStepFormMethods.getValues("social_media_url"),
                sbisiality:
                    typeof sbisialityStepFormMethods.getValues("sbisiality") ===
                    "string"
                        ? ""
                        : `${
                              (
                                  sbisialityStepFormMethods.getValues(
                                      "sbisiality",
                                  ) as IOption
                              ).value
                          }`,
            }),
        )
            .unwrap()
            .then((res) => {
                setCreateResourceForm({
                    ...createResourceForm,
                    error: false,
                    isSubmitting: false,
                    membershipId: res.data.id,
                });

                setActiveStep(activeStep + 1);
                startCountdown();

                sectionRef.current?.scrollIntoView({
                    block: "start",
                    behavior: "smooth",
                });
            })
            .catch((err) => {
                if (err.status === 400) {
                    for (let field of Object.keys(err.data)) {
                        for (let message of err.data[field]) {
                            sbisialityStepFormMethods.setError(
                                "sbisiality" as keyof ISbisialityStepFormFields,
                                {
                                    type: "server",
                                    message: message,
                                },
                            );
                        }
                    }

                    setCreateResourceForm({
                        ...createResourceForm,
                        error: false,
                        isSubmitting: false,
                    });
                }

                if (err.status >= 500) {
                    setCreateResourceForm({
                        ...createResourceForm,
                        error: true,
                        isSubmitting: false,
                    });
                }
            });
    };
    // -- otp form callback(s)
    const handleOtp: SubmitHandler<IOTPFormFields> = (data: IOTPFormFields) => {
        setOtpResourceForm({
            ...otpResourceForm,
            error: false,
            isSubmitting: true,
        });

        dispatch(
            verifyOtp({
                ...data,
                membership: createResourceForm.membershipId,
            }),
        )
            .unwrap()
            .then((res) => {
                otpResourceFormMethods.reset({
                    otp: "",
                });

                setOtpResourceForm({
                    ...otpResourceForm,
                    error: false,
                    isSubmitting: false,
                });

                setActiveStep(activeStep + 1);

                sectionRef.current?.scrollIntoView({
                    block: "start",
                    behavior: "smooth",
                });
            })
            .catch((err) => {
                if (err.status === 400) {
                    for (let field of Object.keys(err.data)) {
                        for (let message of err.data[field]) {
                            otpResourceFormMethods.setError(
                                field as keyof IOTPFormFields,
                                {
                                    type: "server",
                                    message: message,
                                },
                            );
                        }
                    }

                    setOtpResourceForm({
                        ...otpResourceForm,
                        error: false,
                        isSubmitting: false,
                    });
                }

                if (err.status >= 500) {
                    setOtpResourceForm({
                        ...otpResourceForm,
                        error: true,
                        isSubmitting: false,
                    });
                }
            });
    };

    useEffect(() => {
        fetchCategories();
    }, [locale]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <SimpleLayout>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    position: "relative",
                    backgroundSize: "fill",
                    backgroundRepeat: "no-repeat",
                    background:
                        "linear-gradient(55.46deg, #002C62 5.89%, #039FBE 49.57%, #CF1578 89.76%) /* @noflip */",
                    height: isLgDown
                        ? matchMd
                            ? 480
                            : matchSm
                            ? 380
                            : isSmDown
                            ? 280
                            : 580
                        : 580,
                }}
            >
                <Image
                    alt="sbisiali-logo"
                    src={BigSbisialiLogoImage}
                    ratio="16:9"
                    sx={{
                        position: "absolute",
                        width: isLgDown
                            ? matchMd
                                ? 749
                                : matchSm
                                ? 549
                                : isSmDown
                                ? 349
                                : 949
                            : 949,
                        height: isLgDown
                            ? matchMd
                                ? 480
                                : matchSm
                                ? 380
                                : isSmDown
                                ? 280
                                : 580
                            : 580,
                        "& img": {
                            objectFit: "fill",
                        },
                    }}
                />

                <Box
                    mt={isLgDown ? (isMdDown ? (isSmDown ? 11 : 15) : 19) : 22}
                    component={H1}
                    size="medium"
                    color="common.white"
                    bold
                    sx={{
                        textTransform: "uppercase",
                    }}
                >
                    <FormattedMessage id="vipMembershipRequest.header.sections.headerText" />
                </Box>

                <Box
                    mt={locale?.direction === "rtl" ? 4 : 2}
                    component={isSmDown ? Paragraph : H3}
                    size="small"
                    color="common.white"
                    light
                    textAlign="center"
                    fontStyle="italic"
                    lineHeight={1.375}
                    px={2}
                >
                    <FormattedMessage id="vipMembershipRequest.header.sections.description.line1.labelText" />
                    {isMdDown ? " " : <br />}
                    <FormattedMessage id="vipMembershipRequest.header.sections.description.line2.labelText" />
                </Box>

                <Box
                    position="absolute"
                    top={
                        isLgDown
                            ? isMdDown
                                ? isSmDown
                                    ? 200
                                    : 240
                                : 325
                            : 350
                    }
                >
                    <Paper
                        elevation={0}
                        sx={{
                            borderRadius: "32px",
                            boxShadow:
                                "0px 20px 34px 0px rgba(0, 44, 98, 0.15)",
                            height: isLgDown
                                ? isMdDown
                                    ? isSmDown
                                        ? 200
                                        : 257
                                    : 357
                                : 557,
                            width: isLgDown
                                ? isMdDown
                                    ? isSmDown
                                        ? 343
                                        : 645
                                    : 747
                                : 949,
                            backgroundPosition: "center",
                            backgroundSize: "170%",
                            backgroundRepeat: "no-repeat",
                            backgroundColor: "lightgray",
                            backgroundImage: `url(${VideoSectionBackgroundImage})`,
                            "& iframe": {
                                borderRadius: "32px",
                            },
                            "& video": {
                                borderRadius: "32px",
                                objectFit: "fill",
                            },
                        }}
                    >
                        <Promo />
                    </Paper>
                </Box>
            </Box>

            <Stack
                pt={isLgDown ? (isMdDown ? (isSmDown ? 18 : 20) : 30) : 45}
                px={isLgDown ? (isMdDown ? (isSmDown ? 10 : 15) : 25) : 45}
                direction={isSmDown ? "column" : "row"}
                spacing={2}
                justifyContent="center"
                alignItems="center"
            >
                <ExclamationCircleOutlined
                    style={{ fontSize: "38px", color: "#d12f85" }}
                />

                <Paragraph sx={{ fontSize: "13px" }} bold color="secondary">
                    <Paragraph
                        component="span"
                        sx={{ fontSize: "13px" }}
                        color="secondary"
                        bold
                    >
                        <FormattedMessage id="vipMembershipRequest.header.sections.disclaimer.disclaimer.labelText" />
                    </Paragraph>{" "}
                    <FormattedMessage id="vipMembershipRequest.header.sections.disclaimer.description.labelText" />
                </Paragraph>
            </Stack>

            <Box
                ref={sectionRef}
                pt={isLgDown ? (isMdDown ? 5 : 5) : 10}
                mx={2}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                }}
            >
                <Stack direction="column" spacing={1.5} alignItems="center">
                    <Box
                        component={isSmDown ? H3 : H1}
                        size={isSmDown ? "medium" : "small"}
                        color={theme.palette.primary.main}
                        sx={{ fontWeight: 700 }}
                    >
                        <FormattedMessage id="vipMembershipRequest.form.headerText" />
                    </Box>

                    <Paragraph
                        size={isSmDown ? "medium" : "small"}
                        light
                        color={theme.palette.primary.main}
                    >
                        <FormattedMessage id="vipMembershipRequest.form.description.labelText" />
                    </Paragraph>
                </Stack>
            </Box>

            <Box
                mt={3.5}
                px={isLgDown ? (isMdDown ? (isSmDown ? 0 : 5) : 10) : 25}
            >
                <Paper
                    elevation={0}
                    sx={{
                        borderRadius: isMdDown ? 3 : 10,
                        py: 10,
                        px: isLgDown ? 0 : 4.4,
                        boxShadow: "0px 1px 3px 0px #0B02370F",
                    }}
                >
                    <Stepper activeStep={activeStep}>
                        {activeStep === 0 && (
                            <Step index={0} sx={{ width: "100%" }}>
                                <StepContent>
                                    <Stack
                                        spacing={3}
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <H3
                                            color={theme.palette.primary.main}
                                            textAlign="center"
                                            textTransform="uppercase"
                                            bold
                                        >
                                            <FormattedMessage id="vipMembershipRequest.form.steps.becomeASbisialiInsiderNow.header.line1.text" />
                                            <br />
                                            <FormattedMessage id="vipMembershipRequest.form.steps.becomeASbisialiInsiderNow.header.line2.text" />
                                        </H3>

                                        <Image
                                            alt="become-a-sbisiali-insider-now-image"
                                            src={BecomeASbisialiInsiderNowImage}
                                            sx={{
                                                width: 200,
                                                height: 200,
                                                "& img": {
                                                    objectFit: "fill",
                                                },
                                            }}
                                        />

                                        <Button
                                            disabled={
                                                createResourceForm.isSubmitting ||
                                                !categories.isLoaded
                                            }
                                            onClick={() => {
                                                sectionRef.current?.scrollIntoView(
                                                    {
                                                        block: "start",
                                                        behavior: "smooth",
                                                    },
                                                );

                                                setActiveStep(activeStep + 1);
                                            }}
                                            sx={{
                                                color: "#fff",
                                                textTransform: "none",
                                                borderRadius: "39px",
                                                height: "48px",
                                                maxWidth: "366px",
                                                width: "100%",
                                                padding: "10px 20px 10px 20px",
                                                background:
                                                    "linear-gradient(90deg, #028FB2 1.41%, #002C62 96.15%) /* @noflip */",
                                            }}
                                        >
                                            {!categories.isLoaded ? (
                                                <LoadingOutlined
                                                    style={{
                                                        fontSize: 24,
                                                        color: "#fff",
                                                    }}
                                                />
                                            ) : (
                                                intl.formatMessage({
                                                    id: "vipMembershipRequest.form.steps.becomeASbisialiInsiderNow.buttons.register.labelText",
                                                })
                                            )}
                                        </Button>
                                    </Stack>
                                </StepContent>
                            </Step>
                        )}

                        {activeStep === 1 && (
                            <CustomStepContent
                                setActiveStep={setActiveStep}
                                activeStep={activeStep}
                                index={1}
                            >
                                <Stack
                                    spacing={3}
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ width: "100%" }}
                                >
                                    <Stack
                                        spacing={1.5}
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ width: "100%" }}
                                    >
                                        <H3
                                            color={theme.palette.primary.main}
                                            textAlign="center"
                                            textTransform="capitalize"
                                            bold
                                        >
                                            <FormattedMessage id="vipMembershipRequest.form.steps.fullName.headerText" />
                                        </H3>

                                        <Paragraph
                                            color="common.black"
                                            size="small"
                                            light
                                        >
                                            <FormattedMessage id="vipMembershipRequest.form.steps.fullName.descriptionText" />
                                        </Paragraph>
                                    </Stack>

                                    <FormProvider {...fullNameStepFormMethods}>
                                        <form
                                            style={{ width: "100%" }}
                                            onSubmit={fullNameStepFormMethods.handleSubmit(
                                                handleNextStepContinue,
                                            )}
                                        >
                                            <Grid container rowSpacing={3}>
                                                <Grid item xs={12}>
                                                    <TextInput
                                                        labelRequired
                                                        control={
                                                            fullNameStepFormMethods.control
                                                        }
                                                        name="full_name"
                                                        placeholderText="forms.input.fullName.placeholder"
                                                        translatePlaceholder
                                                        disabled={
                                                            fullNameStepFormMethods
                                                                .formState
                                                                .isSubmitting ||
                                                            !categories.isLoaded
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <ContinueButton
                                                        handleBackButton={
                                                            handleBackButton
                                                        }
                                                        disabled={
                                                            fullNameStepFormMethods
                                                                .formState
                                                                .isSubmitting ||
                                                            !categories.isLoaded
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </FormProvider>
                                </Stack>
                            </CustomStepContent>
                        )}

                        {activeStep === 2 && (
                            <CustomStepContent
                                setActiveStep={setActiveStep}
                                activeStep={activeStep}
                                index={2}
                            >
                                <Stack
                                    spacing={3}
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ width: "100%" }}
                                >
                                    <Stack
                                        spacing={1.5}
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ width: "100%" }}
                                    >
                                        <H3
                                            color={theme.palette.primary.main}
                                            textAlign="center"
                                            textTransform="capitalize"
                                            bold
                                        >
                                            <FormattedMessage id="vipMembershipRequest.form.steps.username.headerText" />
                                        </H3>

                                        <Paragraph
                                            color="common.black"
                                            size="small"
                                            light
                                        >
                                            <FormattedMessage id="vipMembershipRequest.form.steps.username.descriptionText" />
                                        </Paragraph>
                                    </Stack>

                                    <FormProvider {...usernameStepFormMethods}>
                                        <form
                                            style={{ width: "100%" }}
                                            onSubmit={usernameStepFormMethods.handleSubmit(
                                                handleNextStepContinue,
                                            )}
                                        >
                                            <Grid container rowSpacing={3}>
                                                <Grid item xs={12}>
                                                    <TextInput
                                                        labelRequired
                                                        control={
                                                            usernameStepFormMethods.control
                                                        }
                                                        name="username"
                                                        placeholderText="forms.input.username.placeholder"
                                                        translatePlaceholder
                                                        onChange={(
                                                            e: any,
                                                            field: any,
                                                        ) => {
                                                            field.onChange(
                                                                e.target.value.toLowerCase(),
                                                            );
                                                        }}
                                                        disabled={
                                                            usernameStepFormMethods
                                                                .formState
                                                                .isSubmitting ||
                                                            !categories.isLoaded
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <ContinueButton
                                                        handleBackButton={
                                                            handleBackButton
                                                        }
                                                        disabled={
                                                            usernameStepFormMethods
                                                                .formState
                                                                .isSubmitting ||
                                                            !categories.isLoaded
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </FormProvider>
                                </Stack>
                            </CustomStepContent>
                        )}

                        {activeStep === 3 && (
                            <CustomStepContent
                                setActiveStep={setActiveStep}
                                activeStep={activeStep}
                                index={3}
                            >
                                <Stack
                                    spacing={3}
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ width: "100%" }}
                                >
                                    <Stack
                                        spacing={1.5}
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ width: "100%" }}
                                    >
                                        <H3
                                            color={theme.palette.primary.main}
                                            textAlign="center"
                                            textTransform="capitalize"
                                            bold
                                        >
                                            <FormattedMessage id="vipMembershipRequest.form.steps.emailAddress.headerText" />
                                        </H3>

                                        <Paragraph
                                            color="common.black"
                                            size="small"
                                            light
                                        >
                                            <FormattedMessage id="vipMembershipRequest.form.steps.emailAddress.descriptionText" />
                                        </Paragraph>
                                    </Stack>

                                    <FormProvider
                                        {...emailAddressStepFormMethods}
                                    >
                                        <form
                                            style={{ width: "100%" }}
                                            onSubmit={emailAddressStepFormMethods.handleSubmit(
                                                handleNextStepContinue,
                                            )}
                                        >
                                            <Grid container rowSpacing={3}>
                                                <Grid item xs={12}>
                                                    <TextInput
                                                        labelRequired
                                                        control={
                                                            emailAddressStepFormMethods.control
                                                        }
                                                        name="email"
                                                        placeholderText="forms.input.email.placeholder"
                                                        translatePlaceholder
                                                        onChange={(
                                                            e: any,
                                                            field: any,
                                                        ) => {
                                                            field.onChange(
                                                                e.target.value.toLowerCase(),
                                                            );
                                                        }}
                                                        disabled={
                                                            emailAddressStepFormMethods
                                                                .formState
                                                                .isSubmitting ||
                                                            !categories.isLoaded
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <ContinueButton
                                                        handleBackButton={
                                                            handleBackButton
                                                        }
                                                        disabled={
                                                            emailAddressStepFormMethods
                                                                .formState
                                                                .isSubmitting ||
                                                            !categories.isLoaded
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </FormProvider>
                                </Stack>
                            </CustomStepContent>
                        )}

                        {activeStep === 4 && (
                            <CustomStepContent
                                setActiveStep={setActiveStep}
                                activeStep={activeStep}
                                index={4}
                            >
                                <Stack
                                    spacing={3}
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ width: "100%" }}
                                >
                                    <Stack
                                        spacing={1.5}
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ width: "100%" }}
                                    >
                                        <H3
                                            color={theme.palette.primary.main}
                                            textAlign="center"
                                            textTransform="capitalize"
                                            bold
                                        >
                                            <FormattedMessage id="vipMembershipRequest.form.steps.phoneNumber.headerText" />
                                        </H3>

                                        <Paragraph
                                            color="common.black"
                                            size="small"
                                            light
                                        >
                                            <FormattedMessage id="vipMembershipRequest.form.steps.phoneNumber.descriptionText" />
                                        </Paragraph>
                                    </Stack>

                                    <FormProvider
                                        {...phoneNumberStepFormMethods}
                                    >
                                        <form
                                            style={{ width: "100%" }}
                                            onSubmit={phoneNumberStepFormMethods.handleSubmit(
                                                handleNextStepContinue,
                                            )}
                                        >
                                            <Grid container rowSpacing={3}>
                                                <Grid item xs={12}>
                                                    <PhoneInput
                                                        labelRequired
                                                        control={
                                                            phoneNumberStepFormMethods.control
                                                        }
                                                        name="phone_number"
                                                        disabled={
                                                            phoneNumberStepFormMethods
                                                                .formState
                                                                .isSubmitting ||
                                                            !categories.isLoaded
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <ContinueButton
                                                        handleBackButton={
                                                            handleBackButton
                                                        }
                                                        disabled={
                                                            phoneNumberStepFormMethods
                                                                .formState
                                                                .isSubmitting ||
                                                            !categories.isLoaded
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </FormProvider>
                                </Stack>
                            </CustomStepContent>
                        )}

                        {activeStep === 5 && (
                            <CustomStepContent
                                setActiveStep={setActiveStep}
                                activeStep={activeStep}
                                index={5}
                            >
                                <Stack
                                    spacing={3}
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ width: "100%" }}
                                >
                                    <Stack
                                        spacing={1.5}
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ width: "100%" }}
                                    >
                                        <H3
                                            color={theme.palette.primary.main}
                                            textAlign="center"
                                            textTransform="capitalize"
                                            bold
                                        >
                                            <FormattedMessage id="vipMembershipRequest.form.steps.socialMediaURL.headerText" />
                                        </H3>

                                        <Paragraph
                                            color="common.black"
                                            size="small"
                                            light
                                        >
                                            <FormattedMessage id="vipMembershipRequest.form.steps.socialMediaURL.descriptionText" />
                                        </Paragraph>
                                    </Stack>

                                    <FormProvider
                                        {...socialMediaURLStepFormMethods}
                                    >
                                        <form
                                            style={{ width: "100%" }}
                                            onSubmit={socialMediaURLStepFormMethods.handleSubmit(
                                                handleNextStepContinue,
                                            )}
                                        >
                                            <Grid container rowSpacing={3}>
                                                <Grid item xs={12}>
                                                    <TextInput
                                                        labelRequired
                                                        control={
                                                            socialMediaURLStepFormMethods.control
                                                        }
                                                        name="social_media_url"
                                                        placeholderText="forms.input.socialMediaUrl.placeholder"
                                                        translatePlaceholder
                                                        disabled={
                                                            socialMediaURLStepFormMethods
                                                                .formState
                                                                .isSubmitting ||
                                                            !categories.isLoaded
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <ContinueButton
                                                        handleBackButton={
                                                            handleBackButton
                                                        }
                                                        disabled={
                                                            socialMediaURLStepFormMethods
                                                                .formState
                                                                .isSubmitting ||
                                                            !categories.isLoaded
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </FormProvider>
                                </Stack>
                            </CustomStepContent>
                        )}

                        {activeStep === 6 && (
                            <CustomStepContent
                                setActiveStep={setActiveStep}
                                activeStep={activeStep}
                                index={6}
                            >
                                <Stack
                                    spacing={3}
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ width: "100%" }}
                                >
                                    <Stack
                                        spacing={1.5}
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ width: "100%" }}
                                    >
                                        <H3
                                            color={theme.palette.primary.main}
                                            textAlign="center"
                                            textTransform="capitalize"
                                            bold
                                        >
                                            <FormattedMessage id="vipMembershipRequest.form.steps.identity.headerText" />
                                        </H3>

                                        <Paragraph
                                            color="common.black"
                                            size="small"
                                            light
                                        >
                                            <FormattedMessage id="vipMembershipRequest.form.steps.identity.description.line1.text" />
                                            <br />
                                            <FormattedMessage id="vipMembershipRequest.form.steps.identity.description.line2.text" />
                                        </Paragraph>
                                    </Stack>

                                    <FormProvider {...identityStepFormMethods}>
                                        <form
                                            style={{ width: "100%" }}
                                            onSubmit={identityStepFormMethods.handleSubmit(
                                                handleNextStepContinue,
                                            )}
                                        >
                                            <Grid container rowSpacing={3}>
                                                <Grid item xs={12}>
                                                    <FilePondInput
                                                        labelRequired
                                                        name="identity"
                                                        disabled={
                                                            identityStepFormMethods
                                                                .formState
                                                                .isSubmitting ||
                                                            !categories.isLoaded
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <ContinueButton
                                                        handleBackButton={
                                                            handleBackButton
                                                        }
                                                        disabled={
                                                            identityStepFormMethods
                                                                .formState
                                                                .isSubmitting ||
                                                            !categories.isLoaded
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </FormProvider>
                                </Stack>
                            </CustomStepContent>
                        )}

                        {activeStep === 7 && (
                            <CustomStepContent
                                setActiveStep={setActiveStep}
                                activeStep={activeStep}
                                index={7}
                            >
                                <Stack
                                    spacing={3}
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ width: "100%" }}
                                >
                                    <Stack
                                        spacing={1.5}
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ width: "100%" }}
                                    >
                                        <H3
                                            color={theme.palette.primary.main}
                                            textAlign="center"
                                            textTransform="capitalize"
                                            bold
                                        >
                                            <FormattedMessage id="vipMembershipRequest.form.steps.sbisiality.headerText" />
                                        </H3>

                                        <Paragraph
                                            color="common.black"
                                            size="small"
                                            light
                                        >
                                            <FormattedMessage id="vipMembershipRequest.form.steps.sbisiality.descriptionText" />
                                        </Paragraph>
                                    </Stack>

                                    <FormProvider
                                        {...sbisialityStepFormMethods}
                                    >
                                        <form
                                            style={{ width: "100%" }}
                                            onSubmit={sbisialityStepFormMethods.handleSubmit(
                                                handleCreate,
                                            )}
                                        >
                                            <Grid container rowSpacing={3}>
                                                <Grid item xs={12}>
                                                    <AutocompleteInput
                                                        labelRequired
                                                        control={
                                                            sbisialityStepFormMethods.control
                                                        }
                                                        name="sbisiality"
                                                        options={categories.data.map(
                                                            (sbisiality) => {
                                                                return {
                                                                    value: sbisiality.id,
                                                                    label: (sbisiality.hasOwnProperty(
                                                                        `name_${
                                                                            locale?.code ??
                                                                            "en"
                                                                        }`,
                                                                    )
                                                                        ? sbisiality[
                                                                              `name_${
                                                                                  locale?.code ??
                                                                                  "en"
                                                                              }` as keyof CategoryModel
                                                                          ]
                                                                        : sbisiality.name) as string,
                                                                };
                                                            },
                                                        )}
                                                        placeholderText="forms.input.sbisiality.placeholder"
                                                        translatePlaceholder
                                                        disabled={
                                                            sbisialityStepFormMethods
                                                                .formState
                                                                .isSubmitting ||
                                                            !categories.isLoaded
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <ContinueButton
                                                        handleBackButton={
                                                            handleBackButton
                                                        }
                                                        disabled={
                                                            createResourceForm.isSubmitting ||
                                                            sbisialityStepFormMethods
                                                                .formState
                                                                .isSubmitting ||
                                                            !categories.isLoaded
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </FormProvider>
                                </Stack>
                            </CustomStepContent>
                        )}

                        {activeStep === 8 && (
                            <CustomStepContent
                                setActiveStep={setActiveStep}
                                activeStep={activeStep}
                                index={8}
                            >
                                <FormProvider {...otpResourceFormMethods}>
                                    <form
                                        style={{ width: "100%" }}
                                        onSubmit={otpResourceFormMethods.handleSubmit(
                                            handleOtp,
                                        )}
                                    >
                                        <Grid
                                            container
                                            py={isMdDown ? 2 : 5}
                                            rowSpacing={2}
                                        >
                                            <Grid item xs={12}>
                                                <Stack
                                                    mb={3}
                                                    direction="column"
                                                    spacing={2.5}
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <H3
                                                        color={
                                                            theme.palette
                                                                .primary.main
                                                        }
                                                    >
                                                        <FormattedMessage id="vipMembershipRequest.form.steps.otp.headerText" />
                                                    </H3>

                                                    <Paragraph
                                                        size="small"
                                                        color="secondary"
                                                        textAlign="center"
                                                    >
                                                        <FormattedMessage id="vipMembershipRequest.form.steps.otp.description.labelText" />
                                                    </Paragraph>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <OTPInput
                                                    length={6}
                                                    control={
                                                        otpResourceFormMethods.control
                                                    }
                                                    name="otp"
                                                    disabled={
                                                        otpResourceFormMethods
                                                            .formState
                                                            .isSubmitting ||
                                                        otpResourceForm.isSubmitting
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                {count === 0 ? (
                                                    <Paragraph
                                                        size="small"
                                                        color="secondary"
                                                    >
                                                        <FormattedMessage id="vipMembershipRequest.form.steps.otp.resend.didNotReceive.labelText" />{" "}
                                                        {!otpResendForm.isSubmitting ? (
                                                            <MuiLink
                                                                color={
                                                                    otpResendForm.error
                                                                        ? "error"
                                                                        : "inherit"
                                                                }
                                                                href="#l"
                                                                onClick={(
                                                                    e,
                                                                ) => {
                                                                    e.preventDefault();
                                                                    resendOtp();
                                                                }}
                                                            >
                                                                {otpResendForm.error ? (
                                                                    <FormattedMessage id="vipMembershipRequest.form.steps.otp.resend.failed.labelText" />
                                                                ) : (
                                                                    <FormattedMessage id="vipMembershipRequest.form.steps.otp.resend.resendAgain.labelText" />
                                                                )}
                                                            </MuiLink>
                                                        ) : (
                                                            <FormattedMessage id="vipMembershipRequest.form.steps.otp.resend.resending.labelText" />
                                                        )}
                                                    </Paragraph>
                                                ) : (
                                                    <Paragraph
                                                        size="small"
                                                        color="secondary"
                                                    >
                                                        <FormattedMessage
                                                            id="vipMembershipRequest.form.steps.otp.resend.requestAfter.labelText"
                                                            values={{
                                                                seconds: count,
                                                            }}
                                                        />
                                                    </Paragraph>
                                                )}
                                            </Grid>
                                            <Grid
                                                mt={2}
                                                item
                                                xs={12}
                                                sx={{ display: "flex" }}
                                                justifyContent="center"
                                            >
                                                <Button
                                                    type="submit"
                                                    disabled={
                                                        otpResourceFormMethods
                                                            .formState
                                                            .isSubmitting ||
                                                        otpResourceForm.isSubmitting
                                                    }
                                                    sx={{
                                                        color: "#fff",
                                                        textTransform: "none",
                                                        borderRadius: "39px",
                                                        height: "48px",
                                                        maxWidth: "366px",
                                                        width: "100%",
                                                        padding:
                                                            "10px 20px 10px 20px",
                                                        borderImageSource:
                                                            "linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0.42) 95.19%) /* @noflip */",
                                                        boxShadow:
                                                            "0px 20px 19px 0px #FFFFFF40 inset",
                                                        background:
                                                            "linear-gradient(90deg, #028FB2 0%, #002C62 100%) /* @noflip */",
                                                        backgroundBlendMode:
                                                            "overlay",
                                                    }}
                                                >
                                                    {otpResourceFormMethods
                                                        .formState
                                                        .isSubmitting ||
                                                    otpResourceForm.isSubmitting ? (
                                                        <LoadingOutlined
                                                            style={{
                                                                fontSize: 24,
                                                                color: "#fff",
                                                            }}
                                                        />
                                                    ) : (
                                                        <FormattedMessage id="vipMembershipRequest.form.steps.otp.resend.submit.labelText" />
                                                    )}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </FormProvider>
                            </CustomStepContent>
                        )}

                        {activeStep === 9 && (
                            <CustomStepContent
                                setActiveStep={setActiveStep}
                                activeStep={activeStep}
                                index={9}
                            >
                                <Stack
                                    spacing={2}
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Stack
                                        spacing={2}
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <H3
                                            color={theme.palette.primary.main}
                                            textTransform="capitalize"
                                            bold
                                        >
                                            <FormattedMessage id="vipMembershipRequest.form.steps.thankYou.headerText" />
                                        </H3>

                                        <H4
                                            color={theme.palette.primary.main}
                                            textTransform="capitalize"
                                            sx={{
                                                fontWeight: 300,
                                            }}
                                        >
                                            <FormattedMessage id="vipMembershipRequest.form.steps.thankYou.description.labelText" />
                                        </H4>
                                    </Stack>

                                    <Image
                                        alt="become-a-sbisiali-insider-now-image"
                                        src={BecomeASbisialiInsiderNowImage}
                                        sx={{
                                            width: 200,
                                            height: 200,
                                            "& img": {
                                                objectFit: "fill",
                                            },
                                        }}
                                    />

                                    <Paragraph
                                        light
                                        color="secondary"
                                        textAlign="center"
                                    >
                                        <FormattedMessage id="vipMembershipRequest.form.steps.thankYou.footer.line1.labelText" />
                                        <br />
                                        <FormattedMessage id="vipMembershipRequest.form.steps.thankYou.footer.line2.labelText" />
                                    </Paragraph>
                                </Stack>
                            </CustomStepContent>
                        )}
                    </Stepper>
                </Paper>
            </Box>

            <SbisialiProvides />
        </SimpleLayout>
    );
}

// ----------------------------------------------------------------------

interface StepsLabelsProps {
    activeStep: number;
    setActiveStep: (step: number) => void;
}

const StepsLabels = ({ activeStep, setActiveStep }: StepsLabelsProps) => {
    // hooks
    // -- theme
    const theme = useTheme();
    // -- i18n
    const intl = useIntl();

    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        size="medium"
                        color="info"
                        checked={activeStep >= 2}
                        onClick={() => setActiveStep(1)}
                        icon={
                            <MinusCircleOutlined
                                style={{
                                    color: theme.palette.info.main,
                                    fontSize: 28,
                                }}
                            />
                        }
                        checkedIcon={
                            <CheckCircleFilled
                                style={{
                                    color: theme.palette.info.main,
                                    fontSize: 28,
                                }}
                            />
                        }
                    />
                }
                label={intl.formatMessage({
                    id: "vipMembershipRequest.form.steps.fullName.headerText",
                })}
            />

            <FormControlLabel
                control={
                    <Checkbox
                        size="medium"
                        color="info"
                        checked={activeStep >= 3}
                        onClick={() => setActiveStep(2)}
                        icon={
                            <MinusCircleOutlined
                                style={{
                                    color: theme.palette.info.main,
                                    fontSize: 28,
                                }}
                            />
                        }
                        checkedIcon={
                            <CheckCircleFilled
                                style={{
                                    color: theme.palette.info.main,
                                    fontSize: 28,
                                }}
                            />
                        }
                    />
                }
                label={intl.formatMessage({
                    id: "vipMembershipRequest.form.steps.username.headerText",
                })}
            />

            <FormControlLabel
                control={
                    <Checkbox
                        size="medium"
                        color="info"
                        checked={activeStep >= 4}
                        onClick={() => setActiveStep(3)}
                        icon={
                            <MinusCircleOutlined
                                style={{
                                    color: theme.palette.info.main,
                                    fontSize: 28,
                                }}
                            />
                        }
                        checkedIcon={
                            <CheckCircleFilled
                                style={{
                                    color: theme.palette.info.main,
                                    fontSize: 28,
                                }}
                            />
                        }
                    />
                }
                label={intl.formatMessage({
                    id: "vipMembershipRequest.form.steps.emailAddress.headerText",
                })}
            />

            <FormControlLabel
                control={
                    <Checkbox
                        size="medium"
                        color="info"
                        checked={activeStep >= 5}
                        onClick={() => setActiveStep(4)}
                        icon={
                            <MinusCircleOutlined
                                style={{
                                    color: theme.palette.info.main,
                                    fontSize: 28,
                                }}
                            />
                        }
                        checkedIcon={
                            <CheckCircleFilled
                                style={{
                                    color: theme.palette.info.main,
                                    fontSize: 28,
                                }}
                            />
                        }
                    />
                }
                label={intl.formatMessage({
                    id: "vipMembershipRequest.form.steps.phoneNumber.headerText",
                })}
            />

            <FormControlLabel
                control={
                    <Checkbox
                        size="medium"
                        color="info"
                        checked={activeStep >= 6}
                        onClick={() => setActiveStep(5)}
                        icon={
                            <MinusCircleOutlined
                                style={{
                                    color: theme.palette.info.main,
                                    fontSize: 28,
                                }}
                            />
                        }
                        checkedIcon={
                            <CheckCircleFilled
                                style={{
                                    color: theme.palette.info.main,
                                    fontSize: 28,
                                }}
                            />
                        }
                    />
                }
                label={intl.formatMessage({
                    id: "vipMembershipRequest.form.steps.socialMediaURL.headerText",
                })}
            />

            <FormControlLabel
                control={
                    <Checkbox
                        size="medium"
                        color="info"
                        checked={activeStep >= 7}
                        onClick={() => setActiveStep(6)}
                        icon={
                            <MinusCircleOutlined
                                style={{
                                    color: theme.palette.info.main,
                                    fontSize: 28,
                                }}
                            />
                        }
                        checkedIcon={
                            <CheckCircleFilled
                                style={{
                                    color: theme.palette.info.main,
                                    fontSize: 28,
                                }}
                            />
                        }
                    />
                }
                label={intl.formatMessage({
                    id: "vipMembershipRequest.form.steps.identity.headerText",
                })}
            />

            <FormControlLabel
                control={
                    <Checkbox
                        size="medium"
                        color="info"
                        checked={activeStep >= 8}
                        onClick={() => setActiveStep(7)}
                        icon={
                            <MinusCircleOutlined
                                style={{
                                    color: theme.palette.info.main,
                                    fontSize: 28,
                                }}
                            />
                        }
                        checkedIcon={
                            <CheckCircleFilled
                                style={{
                                    color: theme.palette.info.main,
                                    fontSize: 28,
                                }}
                            />
                        }
                    />
                }
                label={intl.formatMessage({
                    id: "vipMembershipRequest.form.steps.sbisiality.headerText",
                })}
            />

            <FormControlLabel
                control={
                    <Checkbox
                        size="medium"
                        color="info"
                        checked={activeStep >= 9}
                        onClick={() => setActiveStep(8)}
                        icon={
                            <MinusCircleOutlined
                                style={{
                                    color: theme.palette.info.main,
                                    fontSize: 28,
                                }}
                            />
                        }
                        checkedIcon={
                            <CheckCircleFilled
                                style={{
                                    color: theme.palette.info.main,
                                    fontSize: 28,
                                }}
                            />
                        }
                    />
                }
                label={intl.formatMessage({
                    id: "vipMembershipRequest.form.steps.otp.headerAlternativeText",
                })}
            />
        </FormGroup>
    );
};

// ----------------------------------------------------------------------

interface CustomStepContentProps {
    index: number;
    activeStep: number;
    setActiveStep: (step: number) => void;
    children: ReactNode;
}

const CustomStepContent = ({
    children,
    setActiveStep,
    activeStep,
    index,
}: CustomStepContentProps) => {
    return (
        <Step index={index} sx={{ width: "100%" }}>
            <StepContent sx={{ border: "unset" }}>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={5}
                        lg={5}
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            display: {
                                xs: "none",
                                sm: "none",
                                md: "flex",
                                lg: "flex",
                            },
                        }}
                    >
                        <StepsLabels
                            setActiveStep={setActiveStep}
                            activeStep={activeStep}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={2}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ display: "flex" }}
                    >
                        <Divider orientation="vertical" />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={5}
                        lg={5}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ display: "flex" }}
                    >
                        {children}
                    </Grid>
                </Grid>
            </StepContent>
        </Step>
    );
};

// ----------------------------------------------------------------------

interface ContinueButtonProps {
    disabled: boolean;
    handleBackButton: () => void;
}

const ContinueButton = ({
    disabled = false,
    handleBackButton,
}: ContinueButtonProps) => {
    // hooks
    // -- theme -- breakpoints
    const isMdDown = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md"),
    );
    // -- i18n
    const intl = useIntl();

    if (isMdDown) {
        return (
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Button
                    disabled={disabled}
                    onClick={handleBackButton}
                    sx={{
                        color: "#fff",
                        textTransform: "none",
                        borderRadius: "39px",
                        height: "48px",
                        padding: "10px 20px 10px 20px",
                        background: "#D12F85",
                    }}
                >
                    {disabled ? (
                        <LoadingOutlined
                            style={{
                                fontSize: 24,
                                color: "#fff",
                            }}
                        />
                    ) : (
                        <Stack
                            spacing={1.5}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <ArrowLeftOutlined />

                            <Box>
                                <FormattedMessage id="vipMembershipRequest.form.steps.shared.buttons.back.labelText" />
                            </Box>
                        </Stack>
                    )}
                </Button>

                <Button
                    type="submit"
                    disabled={disabled}
                    sx={{
                        color: "#fff",
                        textTransform: "none",
                        borderRadius: "39px",
                        height: "48px",
                        padding: "10px 20px 10px 20px",
                        background:
                            "linear-gradient(90deg, #028FB2 1.41%, #002C62 96.15%) /* @noflip */",
                    }}
                >
                    {disabled ? (
                        <LoadingOutlined
                            style={{
                                fontSize: 24,
                                color: "#fff",
                            }}
                        />
                    ) : (
                        intl.formatMessage({
                            id: "vipMembershipRequest.form.steps.shared.buttons.continue.labelText",
                        })
                    )}
                </Button>
            </Stack>
        );
    }

    return (
        <Button
            type="submit"
            disabled={disabled}
            sx={{
                color: "#fff",
                textTransform: "none",
                borderRadius: "39px",
                height: "48px",
                minWidth: "100%",
                width: "100%",
                padding: "10px 20px 10px 20px",
                background:
                    "linear-gradient(90deg, #028FB2 1.41%, #002C62 96.15%) /* @noflip */",
            }}
        >
            {disabled ? (
                <LoadingOutlined
                    style={{
                        fontSize: 24,
                        color: "#fff",
                    }}
                />
            ) : (
                intl.formatMessage({
                    id: "vipMembershipRequest.form.steps.shared.buttons.continue.labelText",
                })
            )}
        </Button>
    );
};

// ----------------------------------------------------------------------

export default VipRequestPage;
