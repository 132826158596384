// react
// import { useState } from "react";

// video player
import ReactPlayer from "react-player/lazy";

// ----------------------------------------------------------------------

function Promo() {
    // states
    // -- video player muted
    // const [videoPlayerMuted, setVideoPlayerMuted] = useState<boolean>(true);

    return (
        <ReactPlayer
            url="https://cdn.sbisiali.com/assets/vip/promo.mp4"
            forceHLS
            forceSafariHLS
            playing
            playsinline
            controls
            muted
            // muted={videoPlayerMuted}
            width="100%"
            height="100%"
            // onReady={() => {
            //     setTimeout(() => {
            //         setVideoPlayerMuted(false);
            //     }, 2000);
            // }}
            // onPlay={() => setVideoPlayerMuted(false)}
        />
    );
}

// ----------------------------------------------------------------------

export default Promo;
