// react
import { ReactNode, useMemo } from "react";

// prop types
import PropTypes from "prop-types";

// @mui
// -- theme
import {
    createTheme,
    ThemeProvider as MUIThemeProvider,
    ThemeOptions,
} from "@mui/material/styles";
// -- components
import CssBaseline from "@mui/material/CssBaseline";

// customization
import colors from "./base/colors";
import breakpoints from "./base/breakpoints";
// import typography from "./base/typography";

// utils
import { pxToRem } from "./utils/functions";

// ----------------------------------------------------------------------

interface Props {
    children?: ReactNode;
}

// ----------------------------------------------------------------------

function ThemeProvider({ children, ...props }: Props) {
    // determine theme direction -> left-to-right or right-to-left
    // const themeDirection = "ltr";

    // then, we build our theme options
    const themeOptions = useMemo(
        () =>
            ({
                breakpoints: { ...breakpoints },
                palette: { ...colors },
                typography: {
                    fontFamily:
                        '"Alexandria", "Roboto", "Helvetica", "Arial", sans-serif',
                },
                functions: {
                    pxToRem,
                },
                // direction: "ltr",
            } as ThemeOptions),
        []
        // [themeDirection]
    );

    const theme = createTheme(themeOptions);

    return (
        <MUIThemeProvider theme={theme}>
            <CssBaseline />

            {children}
        </MUIThemeProvider>
    );
}

ThemeProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

// ----------------------------------------------------------------------

export default ThemeProvider;
