// react
import { ReactNode, useEffect } from "react";

// router
import { useLocation } from 'react-router-dom';


// @mui
// -- components
import Box from "@mui/material/Box";

// components
import Header from "./header";
import Footer from "./footer";

// ----------------------------------------------------------------------

interface Props {
    children?: ReactNode;
}

// ----------------------------------------------------------------------

function SimpleLayout({
    children,
}: Props) {
    // hooks
    // -- router
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [pathname]);

    return (
        <>
            <Header />

            <Box mt={-8} bgcolor="#efefef">
                {children}
            </Box>

            <Footer />
        </>
    );
}

// ----------------------------------------------------------------------

export default SimpleLayout;
