// react
import { useState, createContext, ReactNode } from "react";

// prop types
import PropTypes from "prop-types";

// react intl
import { IntlProvider } from "react-intl";

// hooks
import useLocalStorage from "../../hooks/useLocalStorage";

// language files
// -- global
import globalEN_US from "../../i18n/global.en-US.json";
import globalAR_SA from "../../i18n/global.ar-SA.json";
// -- forms
import formsEN_US from "../../i18n/forms.en-US.json";
import formsAR_SA from "../../i18n/forms.ar-SA.json";
// -- filepond
import filePondInputEN_US from "../../i18n/filePondInput.en-US.json";
import filePondInputAR_SA from "../../i18n/filePondInput.ar-SA.json";
// -- phone number
import phoneNumberInputEN_US from "../../i18n/phoneNumberInput.en-US.json";
import phoneNumberInputAR_SA from "../../i18n/phoneNumberInput.ar-SA.json";
// -- membership - vip request
import vipMembershipRequestPageEN_US from "../../i18n/vipMembershipRequestPage.en-US.json";
import vipMembershipRequestPageAR_SA from "../../i18n/vipMembershipRequestPage.ar-SA.json";

// config
import { getDefaultLocale } from "../../config/i18n";

// ----------------------------------------------------------------------

// all languages
const i18nMessages: { [key: string]: any } = {
    "en-US": {
        ...globalEN_US,
        ...formsEN_US,
        ...filePondInputEN_US,
        ...phoneNumberInputEN_US,
        ...vipMembershipRequestPageEN_US
    },
    "ar-SA": {
        ...globalAR_SA,
        ...formsAR_SA,
        ...filePondInputAR_SA,
        ...phoneNumberInputAR_SA,
        ...vipMembershipRequestPageAR_SA
    },
};

// ----------------------------------------------------------------------

interface Locale {
    key: string;
    direction: string;
    default: boolean;
}

interface InitialState {
    locale: string;
    switchLanguage: (locale: Locale) => void;
}

const initialState: InitialState = {
    locale: "en-US",
    switchLanguage: (locale: Locale) => {},
};

// ----------------------------------------------------------------------

const LocalizationContext = createContext(initialState);

// ----------------------------------------------------------------------

interface LocalizationProviderProps {
    children: ReactNode;
}

// ----------------------------------------------------------------------

const LocalizationProvider = ({ children }: LocalizationProviderProps) => {
    // get defaults
    const defaultLocale = getDefaultLocale();

    // hooks
    // -- local storage
    const [localization, setLocalization] = useLocalStorage("i18n", {
        switchLanguage: initialState.switchLanguage,
        locale: defaultLocale?.key || initialState.locale,
    });

    // state
    // -- messages
    const [messages, setMessages] = useState(i18nMessages[localization.locale]);

    // switch language
    const switchLanguage = (locale: Locale) => {
        setMessages(i18nMessages[locale.key]);

        setLocalization({ locale: locale.key });
    };

    return (
        <LocalizationContext.Provider
            value={{ locale: localization.locale, switchLanguage }}
        >
            <IntlProvider
                key={localization.locale}
                locale={localization.locale}
                messages={messages}
                defaultLocale={localization.locale}
            >
                {children}
            </IntlProvider>
        </LocalizationContext.Provider>
    );
};

LocalizationProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

// ----------------------------------------------------------------------

export { LocalizationProvider, LocalizationContext };
