// @mui
// -- styles
import { useTheme } from "@mui/material/styles";
// -- components
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";

// react intl
import { FormattedMessage } from "react-intl";

// swiper
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// config
import { getLocale } from "../../../../../config/i18n";

// hooks
import useLocalization from "../../../../../hooks/useLocalization";

// components
// -- typography
import H1 from "../../../../../components/h1";
import H3 from "../../../../../components/h3";
// -- shared
import Image from "../../../../../components/image";

// images
// -- local
import WhereEntertainmentAndBusinessMeetEnglishImage from "../../../../../assets/images/dkmfre-en.svg";
import WhereEntertainmentAndBusinessMeetArabicImage from "../../../../../assets/images/dkmfre-ar.svg";
import ThinkDifferentThinkSbisialiEnglishImage from "../../../../../assets/images/fkrmvd-en.svg";
import ThinkDifferentThinkSbisialiArabicImage from "../../../../../assets/images/fkrmvd-ar.svg";
import CreateCommunicateGainEnglishImage from "../../../../../assets/images/cjde3e-en.svg";
import CreateCommunicateGainArabicImage from "../../../../../assets/images/cjde3e-ar.svg";
import CommunicateBetterEnglishImage from "../../../../../assets/images/mjisqd-en.svg";
import CommunicateBetterArabicImage from "../../../../../assets/images/mjisqd-ar.svg";
import SbisialiYourNewBusinessPlanEnglishImage from "../../../../../assets/images/jderif-en.svg";
import SbisialiYourNewBusinessPlanArabicImage from "../../../../../assets/images/jderif-ar.svg";
import ExceptionalExperienceEnglishImage from "../../../../../assets/images/dkrncj-en.svg";
import ExceptionalExperienceArabicImage from "../../../../../assets/images/dkrncj-ar.svg";
import DoubleEntertainmentDoubleProfitsEnglishImage from "../../../../../assets/images/cnjdue-en.svg";
import DoubleEntertainmentDoubleProfitsArabicImage from "../../../../../assets/images/cnjdue-ar.svg";

// ----------------------------------------------------------------------

const SbisialiProvides = () => {
    // hooks
    // -- theme
    const theme = useTheme();
    // -- breakpoints
    const isLgDown = useMediaQuery(theme.breakpoints.down("lg"));
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
    const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
    // -- localization
    const localization = useLocalization();

    // get current locale
    const locale = getLocale(localization.locale);

	return (
        <>
            <Box
                pt={isLgDown ? (isMdDown ? (isSmDown ? 5 : 6) : 8) : 10}
                mx={2}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                }}
            >
                <Box component={isSmDown ? H3 : H1}
                    size={isSmDown ? "medium" : "small"}
                    color={theme.palette.primary.main}
                    sx={{ fontWeight: 700 }}
                >
                    <FormattedMessage id="vipMembershipRequest.sbisialiProvides.sections.headerText" />
                </Box>
            </Box>

    	    <Box
    	        mt={1.5}
    	        pb={isSmDown ? 10 : 20}
    	        px={isLgDown ? (isMdDown ? (isSmDown ? 2 : 5) : 10) : 25}
    	        justifyContent="center"
    	        alignItems="center"
    	        sx={{
                    display: "flex",
                    "& .swiper-slide": {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }
                }}
    	    >
                <Swiper
                    loop
                    slidesPerView={1}
                    modules={[Autoplay]}
                    dir={locale?.direction === "rtl" ? 'rtl' : 'ltr'}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false
                    }}
                >
                    {locale?.key === "en-US" ? (
                        <>
                            <SwiperSlide>
                                <Image
                                    alt=""
                                    src={WhereEntertainmentAndBusinessMeetEnglishImage}
                                    sx={{
                                        width: isSmDown ? "60%" : "40%",
                                        height: isSmDown ? "60%" : "40%",
                                        "& img": {
                                            objectFit: "fill"
                                        }
                                    }}
                                />
                            </SwiperSlide>

                            <SwiperSlide>
                                <Image
                                    alt=""
                                    src={ThinkDifferentThinkSbisialiEnglishImage}
                                    sx={{
                                        width: isSmDown ? "70%" : "50%",
                                        height: isSmDown ? "70%" : "50%",
                                        "& img": {
                                            objectFit: "fill"
                                        }
                                    }}
                                />
                            </SwiperSlide>

                            <SwiperSlide>
                                <Image
                                    alt=""
                                    src={CreateCommunicateGainEnglishImage}
                                    sx={{
                                        width: isSmDown ? "70%" : "50%",
                                        height: isSmDown ? "70%" : "50%",
                                        "& img": {
                                            objectFit: "fill"
                                        }
                                    }}
                                />
                            </SwiperSlide>

                            <SwiperSlide>
                                <Image
                                    alt=""
                                    src={CommunicateBetterEnglishImage}
                                    sx={{
                                        width: isSmDown ? "70%" : "40%",
                                        height: isSmDown ? "70%" : "40%",
                                        "& img": {
                                            objectFit: "fill"
                                        }
                                    }}
                                />
                            </SwiperSlide>

                            <SwiperSlide>
                                <Image
                                    alt=""
                                    src={SbisialiYourNewBusinessPlanEnglishImage}
                                    sx={{
                                        width: isSmDown ? "70%" : "45%",
                                        height: isSmDown ? "70%" : "45%",
                                        "& img": {
                                            objectFit: "fill"
                                        }
                                    }}
                                />
                            </SwiperSlide>

                            <SwiperSlide>
                                <Image
                                    alt=""
                                    src={ExceptionalExperienceEnglishImage}
                                    sx={{
                                        width: isSmDown ? "70%" : "40%",
                                        height: isSmDown ? "70%" : "40%",
                                        "& img": {
                                            objectFit: "fill"
                                        }
                                    }}
                                />
                            </SwiperSlide>

                            <SwiperSlide>
                                <Image
                                    alt=""
                                    src={DoubleEntertainmentDoubleProfitsEnglishImage}
                                    sx={{
                                        width: isSmDown ? "60%" : "40%",
                                        height: isSmDown ? "60%" : "40%",
                                        "& img": {
                                            objectFit: "fill"
                                        }
                                    }}
                                />
                            </SwiperSlide>
                        </>
                    ) : (
                        <>
                            <SwiperSlide>
                                <Image
                                    alt=""
                                    src={WhereEntertainmentAndBusinessMeetArabicImage}
                                    sx={{
                                        width: isSmDown ? "60%" : "35%",
                                        height: isSmDown ? "60%" : "35%",
                                        "& img": {
                                            objectFit: "fill"
                                        }
                                    }}
                                />
                            </SwiperSlide>

                            <SwiperSlide>
                                <Image
                                    alt=""
                                    src={ThinkDifferentThinkSbisialiArabicImage}
                                    sx={{
                                        width: isSmDown ? "70%" : "40%",
                                        height: isSmDown ? "70%" : "40%",
                                        "& img": {
                                            objectFit: "fill"
                                        }
                                    }}
                                />
                            </SwiperSlide>

                            <SwiperSlide>
                                <Image
                                    alt=""
                                    src={CreateCommunicateGainArabicImage}
                                    sx={{
                                        width: isSmDown ? "70%" : "25%",
                                        height: isSmDown ? "70%" : "25%",
                                        "& img": {
                                            objectFit: "fill"
                                        }
                                    }}
                                />
                            </SwiperSlide>

                            <SwiperSlide>
                                <Image
                                    alt=""
                                    src={CommunicateBetterArabicImage}
                                    sx={{
                                        width: isSmDown ? "50%" : "25%",
                                        height: isSmDown ? "50%" : "25%",
                                        "& img": {
                                            objectFit: "fill"
                                        }
                                    }}
                                />
                            </SwiperSlide>

                            <SwiperSlide>
                                <Image
                                    alt=""
                                    src={SbisialiYourNewBusinessPlanArabicImage}
                                    sx={{
                                        width: isSmDown ? "70%" : "35%",
                                        height: isSmDown ? "70%" : "35%",
                                        "& img": {
                                            objectFit: "fill"
                                        }
                                    }}
                                />
                            </SwiperSlide>

                            <SwiperSlide>
                                <Image
                                    alt=""
                                    src={ExceptionalExperienceArabicImage}
                                    sx={{
                                        width: isSmDown ? "40%" : "20%",
                                        height: isSmDown ? "40%" : "20%",
                                        "& img": {
                                            objectFit: "fill"
                                        }
                                    }}
                                />
                            </SwiperSlide>

                            <SwiperSlide>
                                <Image
                                    alt=""
                                    src={DoubleEntertainmentDoubleProfitsArabicImage}
                                    sx={{
                                        width: isSmDown ? "50%" : "20%",
                                        height: isSmDown ? "50%" : "20%",
                                        "& img": {
                                            objectFit: "fill"
                                        }
                                    }}
                                />
                            </SwiperSlide>
                        </>
                    )}
                </Swiper>
    	    </Box>
        </>
	);
}

// ----------------------------------------------------------------------

export default SbisialiProvides;
