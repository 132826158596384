// react
import { ReactNode } from "react";

// prop-types
import PropTypes from "prop-types";

// @mui
// -- styles
import { useTheme } from "@mui/material/styles";
// -- components
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

// ----------------------------------------------------------------------

interface Props {
    sx?: any;
    bold?: boolean;
    light?: boolean;
    size?: string;
    color?: string;
    children: ReactNode;
    [rest: string]: any;
}

// ----------------------------------------------------------------------

function Paragraph({
    sx = {},
    light = false,
    bold = false,
    size = "medium",
    color = undefined,
    children,
    ...rest
}: Props) {
    // hooks
    const theme = useTheme();

    // breakpoints
    const mdDownBreakpoint = useMediaQuery(theme.breakpoints.down("md"));

    const smallFontSize = "13px";
    const smallLineHeight = 1.34;

    const mediumFontSize = mdDownBreakpoint ? "16px" : "18px";
    const mediumLineHeight = mdDownBreakpoint ? "22px" : "25px";

    return (
        <Typography
            {...rest}
            variant="body2"
            color={color ?? theme.palette.primary.main}
            sx={{
                letterSpacing: "-0.01em",
                fontWeight: bold ? 600 : light ? 300 : 400,
                fontSize: size === "small" ? smallFontSize : mediumFontSize,
                lineHeight:
                    size === "small" ? smallLineHeight : mediumLineHeight,
                ...sx,
            }}
        >
            {children}
        </Typography>
    );
}

Paragraph.propTypes = {
    sx: PropTypes.any,
    light: PropTypes.bool,
    bold: PropTypes.bool,
    size: PropTypes.string,
    color: PropTypes.string,
    children: PropTypes.node.isRequired,
};

// ----------------------------------------------------------------------

export default Paragraph;
