// @mui
// -- styles
import { useTheme } from '@mui/material/styles';
// -- components
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';

// react intl
import { useIntl, FormattedMessage } from 'react-intl';

// forms
import { useController, UseControllerProps } from 'react-hook-form';

// ----------------------------------------------------------------------

interface Option {
    label: string;
    value: number;
}

interface InputProps extends UseControllerProps<any> {
    labelRequired?: boolean;
    labelText?: string;
    translateLabel?: boolean;
    placeholderText?: string;
    translatePlaceholder?: boolean;
    translateMenuItemsLabels?: boolean;
    disabled?: boolean;
    options: Option[];
}

// ----------------------------------------------------------------------

const Input = ({
    labelRequired = false,
    labelText = undefined,
    translateLabel = false,
    translateMenuItemsLabels = false,
    placeholderText = undefined,
    translatePlaceholder = false,
    disabled = false,
    options,
    ...props
}: InputProps) => {
    // hooks
    // -- theme
    const theme = useTheme();
    // -- i18n
    const intl = useIntl();
    // -- forms
    const { field, formState } = useController(props);

    return (
        <>
            <Stack spacing={1}>
                {labelText && (
                    <InputLabel
                        required={labelRequired}
                        htmlFor={props.name}
                        sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 700
                        }}
                    >
                        {translateLabel ? <FormattedMessage id={labelText} /> : labelText}
                    </InputLabel>
                )}

                <Autocomplete
                    {...field}
                    key={Math.random()}
                    fullWidth
                    size="small"
                    options={options}
                    value={field.value}
                    disabled={disabled}
                    defaultValue={field.value}
                    onChange={(_, value) => field.onChange(value)}
                    getOptionLabel={(option: Option) => {
                        if (option) {
                            return translateMenuItemsLabels ? intl.formatMessage({ id: option.label }) : option.label
                        }

                        return "";
                    }}
                    isOptionEqualToValue={(option: Option, value: Option) => option.value === value.value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            size="medium"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off'
                            }}
                            sx={{
                                bgcolor: "#F5F5F7",
                                "& *": {
                                    border: "unset",
                                },
                            }}
                            placeholder={translatePlaceholder ? intl.formatMessage({ id: placeholderText }) : placeholderText}
                        />
                    )}
                />
            </Stack>

            {formState.errors[`${props.name}`] && (
                <FormHelperText error sx={{ mt: 0.5 }}>
                    {formState.errors[`${props.name}`]?.message as string}
                </FormHelperText>
            )}
        </>
    );
};

// ----------------------------------------------------------------------

export default Input;
