// redux toolkit
import { createAsyncThunk } from "@reduxjs/toolkit";

// axios
import axios from "../../containers/axios";

// ----------------------------------------------------------------------

export interface IOption {
    label: string;
    value: number;
}

export interface IFullNameStepFormFields {
    full_name: string;
}

export interface IUsernameStepFormFields {
    username: string;
}

export interface IEmailAddressStepFormFields {
    email: string;
}

export interface IPhoneNumberStepFormFields {
    phone_number: string;
}

export interface ISocialMediaURLStepFormFields {
    social_media_url: string;
}

export interface IIdentityStepFormFields {
    identity: File[];
}

export interface ISbisialityStepFormFields {
    sbisiality: IOption | string;
}

export interface IOTPFormFields {
    otp: string;
}

export interface ICreatePayload {
    identity: File[];
    full_name: string;
    username: string;
    email: string;
    social_media_url: string;
    country_code: string;
    phone_number: string;
    sbisiality: string;
}

export interface IOTPPayload {
    otp: string;
    membership: number;
}

// ----------------------------------------------------------------------

export interface VipRequestModel {
    id: number;
    status: number;
    username: string;
    email: string;
    is_email_verified: boolean;
    country_code: string;
    phone_number: string;
    identity: string;
    social_media_url: string;
    description: string;
    created_date: string;
    updated_date: string;
    category: number;
}

// ----------------------------------------------------------------------

export const create = createAsyncThunk(
    "membership.requests.vip/post",
    (data: ICreatePayload, { rejectWithValue }) => {
        const formData = new FormData();

        if (data.identity) {
            data.identity.forEach((identity) => {
                formData.append("identity", identity);
            });
        }

        formData.append("full_name", data.full_name);
        formData.append("username", data.username);
        formData.append("email", data.email);
        formData.append("social_media_url", data.social_media_url);
        formData.append("phone_number", data.phone_number);
        formData.append("country_code", data.country_code);
        formData.append("category", `${data.sbisiality}`);

        return axios
            .post("membership/requests/vip", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => res)
            .catch((err) =>
                rejectWithValue({
                    status: err.response.status,
                    statusText: err.response.statusText,
                    data: err.response.data,
                }),
            );
    },
);

// ----------------------------------------------------------------------

export const verifyOtp = createAsyncThunk(
    "auth.otp.verify/post",
    (data: IOTPPayload, { rejectWithValue }) => {
        return axios
            .post("membership/requests/vip/otp/verify", data)
            .then((res) => res)
            .catch((err) =>
                rejectWithValue({
                    status: err.response.status,
                    statusText: err.response.statusText,
                    data: err.response.data,
                }),
            );
    },
);
