// router
import {
    createBrowserRouter,
    Navigate,
    RouterProvider as ReactRouterDomRouterProvider,
} from "react-router-dom";

// routes
// -- constants
import routes from "../../constants/routes";

// ----------------------------------------------------------------------

// elements
// -- membership - vip request
import MembershipVipRequestPage from "../../pages/membership/vipRequest";

// ----------------------------------------------------------------------

const router = createBrowserRouter([
    {
        path: routes.landing,
        element: <MembershipVipRequestPage />
    },
    {
        path: "*",
        element: <Navigate to={routes.landing} />
    }
]);

// ----------------------------------------------------------------------

const RouterProvider = () => <ReactRouterDomRouterProvider router={router} />;

// ----------------------------------------------------------------------

export default RouterProvider;
