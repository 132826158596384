// react
import { useContext } from 'react';

// settings context
import { LocalizationContext } from '../contexts/localization';

// ----------------------------------------------------------------------

const useLocalization = () => useContext(LocalizationContext);

// ----------------------------------------------------------------------

export default useLocalization;
