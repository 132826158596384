// react
import { ReactElement, useState, useEffect } from "react";

// axios
import axios, {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    AxiosError,
} from "axios";

// hooks
// import useLocalization from '../../hooks/useLocalization';

// ----------------------------------------------------------------------

interface IAxiosContainer {
    children: ReactElement<any, any>;
}

// ----------------------------------------------------------------------

const basePath: string = "https://api.sbisiali.com/engine/api/v1/";

const axiosInstance: AxiosInstance = axios.create({
    baseURL: basePath,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Api-Key": "jDRvrSEp.euGRk34UdDCs9pNX4iIEbrLnFsYtEBnh",
    } as any,
    withCredentials: true,
} as AxiosRequestConfig);

// ----------------------------------------------------------------------

const AxiosContainer = ({ children }: IAxiosContainer): JSX.Element | null => {
    // hooks
    // -- localization
    // const localization = useLocalization();

    // state
    const [isSet, setIsSet] = useState(false);

    axiosInstance.interceptors.request.use(
        (config: any) => {
            // config.headers.set('Accept-Language', localization.locale.substring(0, localization.locale.indexOf('-')));
            config.headers.set(
                "Accept-Language",
                JSON.parse(
                    window.localStorage.getItem("i18n") as string,
                ).locale.substring(
                    0,
                    JSON.parse(
                        window.localStorage.getItem("i18n") as string,
                    ).locale.indexOf("-") as string,
                ) as string,
            );

            return config;
        },
        (error) => Promise.reject(error),
    );

    useEffect(() => {
        const resInterceptor = (response: AxiosResponse) => response;

        const errInterceptor = (error: AxiosError) => Promise.reject(error);

        const interceptor = axiosInstance.interceptors.response.use(
            resInterceptor,
            errInterceptor,
        );

        setIsSet(true);

        return () => axiosInstance.interceptors.response.eject(interceptor);
    }, []);

    if (isSet) return children;

    return null;
};

// ----------------------------------------------------------------------

export default axiosInstance;
export { basePath, AxiosContainer };
