// react
import { useEffect, ReactNode } from 'react';

// prop types
import PropTypes from 'prop-types';

// rtl
import rtlPlugin from 'stylis-plugin-rtl';

// emotion
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

// config
import { getLocale } from '../../config/i18n';

// hooks
import useLocalization from '../../hooks/useLocalization';

// ----------------------------------------------------------------------

interface RTLProps {
    children: ReactNode;
}

// ----------------------------------------------------------------------

const RTL = ({ children }: RTLProps) => {
    // hooks
    // -- localization
    const localization = useLocalization();

    // get current locale
    const locale = getLocale(localization.locale);

    useEffect(() => {
        document.dir = locale?.direction || 'ltr';
    }, [locale?.direction]);

    const cacheRtl = createCache({
        key: 'muirtl',
        stylisPlugins: locale?.direction === 'rtl' ? [rtlPlugin] : []
    });

    return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
};

RTL.propTypes = {
    children: PropTypes.node.isRequired
};

// ----------------------------------------------------------------------

export default RTL;
