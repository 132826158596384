export const locales = [
    {
        label: 'English',
        key: 'en-US',
        code: 'en',
        direction: 'ltr',
        default: true
    },
    {
        label: 'العربية',
        key: 'ar-SA',
        code: 'ar',
        direction: 'rtl',
        default: false
    }
];

export const getLocale = (key: string) => {
    return locales.find((locale) => locale.key === key);
};

export const getDefaultLocale = () => {
    return locales.find((locale) => locale.default === true);
};
