// @mui
// -- components
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import MuiLink from "@mui/material/Link";

// components
// -- typography
import Paragraph from "../../components/paragraph";
// -- shared
import Image from "../../components/image";

// images
// -- local
import LinkedInLogoImage from "../../assets/images/dfeeas.svg";

// ----------------------------------------------------------------------

const Footer = () => {
    return (
        <Grid rowSpacing={2} container py={2} bgcolor="#efefef">
            <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                justifyContent="center"
                alignItems="center"
                sx={{ display: "flex" }}
                order={{ xs: 1, sm: 1, md: 1, lg: 1 }}
            >
                <Image
                    alt="sbisiali-logo"
                    src="https://cdn.sbisiali.com/assets/logo/color.png"
                    sx={{
                        width: 140,
                        height: 40,
                        "& img": {
                            objectFit: "fill",
                        },
                    }}
                />
            </Grid>
            <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                justifyContent="center"
                alignItems="center"
                sx={{ display: "flex" }}
                order={{ xs: 3, sm: 2, md: 2, lg: 2 }}
            >
                <Paragraph size="small" color="rgba(5, 25, 50, 0.48)">
                    {"Powered By Sbisiali Portal LLC"}
                </Paragraph>
            </Grid>
            <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                justifyContent="center"
                alignItems="center"
                sx={{ display: "flex" }}
                order={{ xs: 2, sm: 3, md: 3, lg: 3 }}
            >
                <Stack direction="row" spacing={1.5}>
                    <MuiLink
                        href="https://www.facebook.com/profile.php?id=61551232850727&mibextid=ZbWKwL"
                        target="_blank"
                    >
                        <Image
                            alt="facebook-logo"
                            src="https://cdn.sbisiali.com/assets/social/facebook.png"
                            sx={{
                                width: 27,
                                height: 27,
                                "& img": {
                                    objectFit: "fill",
                                },
                            }}
                        />
                    </MuiLink>

                    <MuiLink
                        href="https://instagram.com/sbisiali_business?igshid=NzZhOTFlYzFmZQ=="
                        target="_blank"
                    >
                        <Image
                            alt="instagram-logo"
                            src="https://cdn.sbisiali.com/assets/social/instagram.png"
                            sx={{
                                width: 27,
                                height: 27,
                                "& img": {
                                    objectFit: "fill",
                                },
                            }}
                        />
                    </MuiLink>

                    <MuiLink
                        href="https://www.tiktok.com/@sbisiali_app?_t=8ffD4Y13VYI&_r=1"
                        target="_blank"
                    >
                        <Image
                            alt="tiktok-logo"
                            src="https://cdn.sbisiali.com/assets/social/tiktok.png"
                            sx={{
                                width: 27,
                                height: 27,
                                "& img": {
                                    objectFit: "fill",
                                },
                            }}
                        />
                    </MuiLink>

                    <MuiLink
                        href="https://twitter.com/sbisiali?t=KQPbN0N73XzoKPADjAXc-w&s=09"
                        target="_blank"
                    >
                        <Image
                            alt="twitter-logo"
                            src="https://cdn.sbisiali.com/assets/social/x.png"
                            sx={{
                                width: 27,
                                height: 27,
                                "& img": {
                                    objectFit: "fill",
                                },
                            }}
                        />
                    </MuiLink>

                    <MuiLink
                        href="https://www.linkedin.com/company/71981414"
                        target="_blank"
                    >
                        <Image
                            alt="linkedin-logo"
                            src={LinkedInLogoImage}
                            sx={{
                                width: 27,
                                height: 27,
                                "& img": {
                                    objectFit: "fill",
                                },
                            }}
                        />
                    </MuiLink>
                </Stack>
            </Grid>
        </Grid>
    );
};

// ----------------------------------------------------------------------

export default Footer;
