// react
import { ReactNode } from "react";

// prop-types
import PropTypes from "prop-types";

// @mui
// -- styles
import { useTheme } from "@mui/material/styles";
// -- components
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

// ----------------------------------------------------------------------

interface Props {
    sx?: any;
    bold?: boolean;
    light?: boolean;
    color?: string;
    size?: string;
    children: ReactNode;
    [rest: string]: any;
}

// ----------------------------------------------------------------------

function H3({
    sx = {},
    size = "medium",
    bold = false,
    light = false,
    color = undefined,
    children,
    ...rest
}: Props) {
    // hooks
    const theme = useTheme();

    // breakpoints
    const lgDownBreakpoint = useMediaQuery(theme.breakpoints.down("lg"));
    const mdOnlyBreakpoint = useMediaQuery(theme.breakpoints.only("md"));
    const mdDownBreakpoint = useMediaQuery(theme.breakpoints.down("md"));

    const smallLineHeight = 1;
    const smallLetterSpacing = "inherit";
    const smallFontSize = mdDownBreakpoint ? "19px" : "24px";

    const mediumLineHeight = 1;
    const mediumLetterSpacing = mdDownBreakpoint
        ? mdOnlyBreakpoint
            ? "-0.03em"
            : "-1px"
        : "-0.03em";
    const mediumFontSize = mdDownBreakpoint ? "24px" : "32px";

    const largeLineHeight = 1;
    const largeLetterSpacing = lgDownBreakpoint
        ? mdOnlyBreakpoint
            ? "-1px"
            : "-0.03em"
        : "-0.03em";
    const largeFontSize = lgDownBreakpoint
        ? mdOnlyBreakpoint
            ? "32px"
            : "24px"
        : "48px";

    return (
        <Typography
            {...rest}
            variant="h3"
            color={color ?? theme.palette.primary.main}
            sx={{
                fontWeight: light ? 300 : (bold ? 700 : 400),
                fontSize:
                    size === "small"
                        ? smallFontSize
                        : size === "medium"
                        ? mediumFontSize
                        : largeFontSize,
                lineHeight:
                    size === "small"
                        ? smallLineHeight
                        : size === "medium"
                        ? mediumLineHeight
                        : largeLineHeight,
                letterSpacing:
                    size === "small"
                        ? smallLetterSpacing
                        : size === "medium"
                        ? mediumLetterSpacing
                        : largeLetterSpacing,
                ...sx,
            }}
        >
            {children}
        </Typography>
    );
}

H3.propTypes = {
    sx: PropTypes.any,
    bold: PropTypes.bool,
    light: PropTypes.bool,
    color: PropTypes.string,
    size: PropTypes.string,
    children: PropTypes.node.isRequired,
};

// ----------------------------------------------------------------------

export default H3;
